/**
 * Save value to browser localstorage
 * @param {string} item string
 * @param {*} data 
 * @returns 
 */

export const saveToLocalStorage = (item, data) => {
    return localStorage.setItem(item, JSON.stringify(data));
}

/**
 * Return Value from localstorage
 * @param {string} item 
 * @returns 
 */
export const getFromLocalStorage = (item) => {
    return JSON.parse(localStorage.getItem(item));
}

/**
 * Save cookie
 * @param {string} cname 
 * @param {string} cvalue 
 */
export const setCookie = (cname, cvalue) => {
    document.cookie = cname + "=" + cvalue + ";";
}

/**
 * Get cookie
 * @param {string} cname 
 * @returns 
 */
export const getCookie = (cname) => {
    let name = cname + "=";
    let ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}