import { Services } from "../../../__services";
import { CONSTANT } from "../../../Utils";
import { processVisitData, processObsData } from "./dataFunctions";

export const getTotalVisit = async (param) => {
    let params = `visit_type_id=${CONSTANT.VISITFIRST}&customQuery=location,patient(person),encounters(creator)`;
    params += param;
    let allVisits = await Services.GetVisitWithParams(params);
    let { awaitingConsult, visitInProgress, visitComplete } = await processVisitData(allVisits.rows, 'user');
    return { awaitingConsult, visitInProgress, visitComplete, totalVisit: allVisits.total }
}

export const getTotalDiagnosis = async (param, location, filterLocation) => {
    let params = `concept_id=${CONSTANT.DIAGNOSISLEFTEYELENS},${CONSTANT.DIAGNOSISLEFTEYEADDITIONALPATHALOGY},${CONSTANT.DIAGNOSISRIGHTEYEADDITIONALPATHALOGY},${CONSTANT.DIAGNOSISRIGHTEYELENS}`;
    params += param;
    params += `&customQuery=obs_string,person`;
    let allDiagnosis = await Services.GetObsWithParams(params);
    let diagnosis = await processObsData(allDiagnosis.rows, location, filterLocation);
    return diagnosis
}

export const getALLEncounterWithObs = async () => {
    let params = `encounter_type_id=${CONSTANT.ENCOUNTERVISITINPROGRESS}&customQuery=patient(person),obs`;
    let allEncounters = await Services.GetEncounterWithParams(params);
    return { allEncounters };
}