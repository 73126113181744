import { useContext } from "react";
import { Context } from "../ContextProvider";
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { Login } from '../Login';
import { HomePage } from '../Homepage';
import { VisitSummary } from '../VisitSummary';
import { ProtectedLayout } from '../ProtectedLayout';
import { Admin } from '../Admin';
import { Concept } from '../Admin/ClinicalProtocol/Concept';
import { User } from '../Admin/User';
import { ClinicalProtocol } from '../Admin/ClinicalProtocol';
import { LicenceKey } from '../Admin/ClinicalProtocol/Protocol';
import { VisualAcuity } from '../Admin/ClinicalProtocol/VisualAcuity';
import { Dashboard } from '../Admin/DataDashboard';
import { useIdleTimer } from 'react-idle-timer';

function App() {
  const { updateState } = useContext(Context);
  const navigate = useNavigate();

  const handleOnIdle = event => {
    updateState('user', null)
    navigate('/')
  }

  // const handleOnActive = event => {
  //   console.log('user is active', event)
  //   console.log('time remaining', getRemainingTime())
  // }

  // const handleOnAction = event => {
  //   console.log('user did something', event)
  // }

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * 10,
    onIdle: handleOnIdle,
    // onActive: handleOnActive,
    // onAction: handleOnAction,
    debounce: 500
  })

  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route element={<ProtectedLayout />}>
        <Route path="/home" element={<HomePage />} />
        <Route path="/visitsummary/:id" element={<VisitSummary />} />
        <Route path="/admin" element={<Admin />} >
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="user" element={<User />} />
          <Route path="clinical" element={<ClinicalProtocol />} >
            <Route path="protocol" element={<LicenceKey />} />
            <Route path="acuity" element={<VisualAcuity />} />
            <Route path="concept" element={<Concept />} />
          </Route>
        </Route>
      </Route>
    </Routes>
  );

}

export default App;