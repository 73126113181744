import { useState, useRef, useEffect } from "react";
import { Card } from "antd";
import { getTotalDiagnosis } from '../../data';
import { SpinnerComponent } from "../../../../../Component/Common";

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';


const chartData = [
    { type: 'column', title: 'Total Diagnosis', xAxis: ['Mature cataract', 'Immature cataract', 'Clear lens', 'PCIOL', 'Aphakia'] },
    { type: 'column', title: 'Lens Diagnosis by Location', xAxis: ['Mature cataract', 'Immature cataract', 'Clear lens', 'PCIOL', 'Aphakia'] },
    { type: 'spline', title: 'Lens Diagnosis by Time', xAxis: ['Sun', 'Mon', 'Tue', 'Wed', 'Thrus', 'Fri', 'Sat'] },
    { type: 'column', title: 'Total Additional Pathology', xAxis: ['Active corneal infection', 'Inactive corneal opacity', 'Epithelial Defect', 'Corneal Abrasion', 'Pterygium'] },
    { type: 'column', title: 'Additional Pathilogy by Location', xAxis: ['Active corneal infection', 'Inactive corneal opacity', 'Epithelial Defect', 'Corneal Abrasion', 'Pterygium'] },
    { type: 'spline', title: 'Additional Pathology by Time', xAxis: ['Sun', 'Mon', 'Tue', 'Wed', 'Thrus', 'Fri', 'Sat'] },
]

export const DiagnosisAnalysis = ({ filterData, allLocation }) => {
    const [filter, setFilter] = useState();
    const [totalDiagnosis, setTotalDiagnosis] = useState();
    const [diagnosisByLocation, setDiagnosisByLocation] = useState();
    const [diagnosisByTime, setDiagnosisByTime] = useState();
    const [totalPathology, setTotalPathology] = useState();
    const [pathologyByLocation, setPathologyByLocation] = useState();
    const [pathologyByTime, setPathologyByTime] = useState();
    const [loading, setLoading] = useState(true);
    const isFirstRender = useRef(true);

    useEffect(() => {
        if (isFirstRender.current) {
            getAllData();
            isFirstRender.current = false
            return;
        }
        try {
            if ((filterData && !filter) || (filterData && JSON.stringify(Object.values(filterData)) !== JSON.stringify(Object.values(filter)))) {
                setFilter(filterData);
                setLoading(true);
                getAllData(Object.keys(filterData)[0], Object.values(filterData)[0]);
            }
        } catch (e) {
            console.log('Filter Exception :', e)
        }
        // eslint-disable-next-line
    }, [filterData])

    const getAllData = async (type, value) => {
        let lastMonth = new Date(new Date().setDate(new Date().getDate() - 30)).toISOString().split('T')[0];
        let todayDate = new Date().toISOString().split('T')[0];
        let dates = [lastMonth, todayDate];
        let filterLocation;
        let param = `&created_at=${dates}`;
        if (type) {
            if (type === 'date') {
                if (!value.length) {
                    param = `&created_at=${dates}`
                } else {
                    param = `&created_at=${value}`;
                }
            } else if (type === 'location') {
                filterLocation = value;
            } else if (type === 'healthWorker' || type === 'doctor') {
                param = `&creator_id${value}`;
            }
        }
        await getTotalDiagnosis(param, allLocation, filterLocation).then(res => {
            // console.log(res)
            if (res.lens) {
                let { lensDiagnosis, byLocation, byDay } = res?.lens;
                if (lensDiagnosis) {
                    let { aphakia, clearLens, immatureCataract, matureCataract, pciol } = lensDiagnosis
                    setTotalDiagnosis([{ data: [matureCataract.length, immatureCataract.length, clearLens.length, pciol.length, aphakia.length], showInLegend: false }])
                }
                if (byLocation) {
                    let data = []
                    Object.keys(byLocation).forEach((location, index) => {
                        data.push({
                            showInLegend: true,
                            name: location,
                            data: Object.values(byLocation)[index]
                        })
                    })
                    setDiagnosisByLocation(data);
                }
                if (byDay) {
                    let data = []
                    Object.keys(byDay).forEach((day, index) => {
                        data.push({
                            showInLegend: true,
                            name: day,
                            data: Object.values(byDay)[index]
                        })
                    })
                    setDiagnosisByTime(data)
                }
            }
            if (res.add) {
                let { additionalDiagnosis, byLocation, byDay } = res?.add;
                if (additionalDiagnosis) {
                    let { activeCorneal, inactiveCorneal, epithelial, corneal, pterygium } = additionalDiagnosis;
                    setTotalPathology([{ data: [activeCorneal.length, inactiveCorneal.length, epithelial.length, corneal.length, pterygium.length], showInLegend: false }])
                }
                if (byLocation) {
                    let data = []
                    Object.keys(byLocation).forEach((day, index) => {
                        data.push({
                            showInLegend: true,
                            name: day,
                            data: Object.values(byLocation)[index]
                        })
                    })
                    setPathologyByLocation(data)
                }
                if (byDay) {
                    let data = []
                    Object.keys(byDay).forEach((day, index) => {
                        data.push({
                            showInLegend: true,
                            name: day,
                            data: Object.values(byDay)[index]
                        })
                    })
                    setPathologyByTime(data)
                }
            }
            setLoading(false);
        });
    }

    const chartOption = (type, title, xAxis, value = []) => {
        return {
            chart: {
                type
            },
            title: {
                text: title
            },
            accessibility: {
                enabled: false
            },
            xAxis: { categories: xAxis },
            series: value
        }
    }

    return (
        <Card title={(<>Diagnosis Analysis</>)}>
            {loading ? <SpinnerComponent /> :
                <>
                    <div id="container" className="highcharts-light">
                        <div style={{ display: 'flex', justifyContent: 'space-evenly', flexWrap: "wrap", gap: '1rem' }}>
                            {chartData.map((chart, index) =>
                                <Card style={{ width: '32%' }} key={index}>
                                    <HighchartsReact containerProps={{ style: { height: "100%" } }} highcharts={Highcharts}
                                        options={chartOption(chart.type, chart.title, chart.xAxis, index === 0 ? totalDiagnosis : index === 1 ? diagnosisByLocation : index === 2 ? diagnosisByTime : index === 3 ? totalPathology : index === 4 ? pathologyByLocation : index === 5 ? pathologyByTime : null)} />
                                </Card>
                            )}
                        </div>
                    </div>
                </>}
        </Card>
    )
}