
import { useState, useRef, useEffect } from "react";
import { Input } from 'antd';
import { Services } from '../../__services';
import { ModalComponent, SpinnerComponent, TableComponent } from "../../Component/Common";
import { SearchBarWrapper } from "./style";
const { Search } = Input;
let timer;


export const PatientSearch = ({ onClick }) => {
    const [spinner, setSpinner] = useState(false);
    const [allPatient, setAllPatient] = useState([]);
    const [filteredPatient, setFilteredPatient] = useState([]);

    const isFirstRender = useRef(true);

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false
            return;
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onChangeHandler = (type, value) => {
        if (type === 'patientId') {
            getPatientsWithPatientId(value, 2000);
        } else if (type === 'patientName') {
            getPatientsWithPatientName(value, 2000);
        }
    }

    const getPatientsWithPatientId = (value, timing) => {
        try {
            clearTimeout(timer);
            timer = setTimeout(() => {
                setSpinner(true);
                let params = ``;
                params += `patient_identifier=Like(${value})&customQuery=person,visits(location)`;
                Services.GetPatientWithParams(params).then(response => {
                    console.log(response)
                    if (response.rows?.length) {
                        let allSearchablePatient = response.rows.map(pt => {
                            return {
                                key: pt?.visits[0]?.id,
                                patient_id: pt?.id,
                                patient_identifier: pt?.patient_identifier,
                                abha_no: pt?.abha_no,
                                name: `${pt.person?.first_name} ${pt?.person?.middle_name || ''} ${pt?.person?.last_name}`,
                                gender: pt?.person?.gender,
                                dob: pt?.person?.birthdate,
                                created_at: pt?.visits[0]?.created_at,
                                location: pt?.visits[0]?.location?.name,
                            }
                        }
                        );
                        setSpinner(false);
                        setFilteredPatient(allSearchablePatient);
                        // setAllPatient(allSearchablePatient);
                    } else {
                        setSpinner(false);
                        setFilteredPatient([]);
                    }
                })
            }, timing);
        } catch (e) {
            console.log('Get Patient Exception: ', e);
        }
    }

    const getPatientsWithPatientName = (value, timing) => {
        try {
            clearTimeout(timer);
            timer = setTimeout(() => {
                setSpinner(true)
                let name = value.split(' ');
                let params = ``;
                params += `first_name=Like(${name[0]})&customQuery=patient,patient.visits(location)`;
                Services.GetPersonWithParams(params).then(response => {
                    console.log(response)
                    if (response.rows?.length) {
                        let allSearchablePatient = response.rows.map(pt => {
                            return {
                                key: pt?.patient?.visits[0]?.id,
                                patient_id: pt?.patient?.id,
                                patient_identifier: pt?.patient?.patient_identifier,
                                abha_no: pt?.patient?.abha_no,
                                name: `${pt?.first_name} ${pt?.middle_name || ''} ${pt?.last_name}`,
                                gender: pt?.gender,
                                dob: pt?.birthdate,
                                created_at: pt?.patient?.visits[0]?.created_at,
                                location: pt?.patient?.visits[0]?.location?.name,
                            }
                        }
                        );
                        setSpinner(false);
                        setFilteredPatient(allSearchablePatient);
                        // setAllPatient(allSearchablePatient);
                    } else {
                        setSpinner(false);
                        setFilteredPatient([]);
                    }
                })
            }, timing);
        } catch (e) {
            console.log('Get Patient Exception: ', e);
        }
    }

    const filterPatient = (value = '') => {
        let lowercasedFilter = value !== undefined ? value.toString().toLowerCase() : '';
        let filteredData = allPatient.filter(item => item?.name?.toString().toLowerCase().includes(lowercasedFilter) || item.patient_identifier.toString().toLowerCase().includes(lowercasedFilter));
        setFilteredPatient(filteredData);
        setSpinner(false);
    }

    return (
        <>
            <ModalComponent
                width='900px'
                open={true}
                title='Patient Search'
                onCancel={() => onClick('showPatientSearch', false)}
                saveData={() => onClick('showPatientSearch', false)}>
                <SearchBarWrapper>
                    <Input
                        placeholder="Search with Patient Id"
                        size='large'
                        style={{ width: 300 }}
                        allowClear
                        enterButton="Search"
                        onChange={(e) => onChangeHandler('patientId', e.target.value)} />
                    <Input
                        placeholder="Search with Patient Name"
                        size='large'
                        style={{ width: 300 }}
                        allowClear
                        enterButton="Search"
                        onChange={(e) => onChangeHandler('patientName', e.target.value)} />
                </SearchBarWrapper>
                {spinner ?
                    <SpinnerComponent /> :
                    <>
                        <br />
                        <br />
                        <TableComponent search={true} data={filteredPatient} rowPerPage='5' />

                    </>
                }
            </ModalComponent>
        </>
    )
}