import styled from "styled-components";

export const InputWrapper = styled.div`
    display: flex;
    gap: 20%;
`;

export const InfectionTypeWrapper = styled.div`
    display: flex;
    gap: 20%;
`;

export const IndiltrateWrapper = styled.div`
    display: flex;
    gap: 5%;
`;

export const SpecificExamWrapper = styled.div`
    display: flex;
    gap: 10%;
`;