import { Collapse, theme, Card } from 'antd';
import { TableComponent } from '../../../Component/Common';
import { CaretRightOutlined } from '@ant-design/icons';
import { BadgeWrapper, CardData, Badge } from './style';

const { Panel } = Collapse;

export const CoordinatorComponent = ({ visitMicrobiology, visitMicrobiologyResult, visitMicrobiologyComplete }) => {
    const { token } = theme.useToken();
    const panelStyle = {
        marginBottom: 24,
        background: token.colorFillAlter,
        borderRadius: token.borderRadiusLG,
        border: 'none'
    };

    return (
        <>
            {/* <BadgeWrapper>
                <Card size="small" style={{ width: '80%' }}>
                    <CardData>
                        <div style={{ fontSize: 20 }}>Microbiology Results Not Entered</div>
                        <Badge color='#faad14'>{visitMicrobiology.length}</Badge>
                    </CardData>
                </Card>
                <Card size="small" style={{ width: '80%' }}>
                    <CardData>
                        <div style={{ fontSize: 20 }}>Microbiology Results Partially Complete</div>
                        <Badge color='#0096FF'>{visitMicrobiologyResult.length}</Badge>
                    </CardData>
                </Card>
                <Card size="small" style={{ width: '80%' }}>
                    <CardData>
                        <div style={{ fontSize: 20 }}> Microbiology Results Complete</div>
                        <Badge color='green'>{visitMicrobiologyComplete.length}</Badge>
                    </CardData>
                </Card>
            </BadgeWrapper> */}
            <Collapse
                bordered={false}
                size='large'
                defaultActiveKey={['1', '2', '3']}
                expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
            >
                <Panel header={(<div>
                    <div style={{ fontSize: 20 }}>Microbiology Results Not Entered</div>
                    <div style={{ fontSize: 14 }}>Total: {visitMicrobiology?.length}</div>
                </div>
                )} key="1" style={panelStyle}>
                    {visitMicrobiology?.length ?
                        <TableComponent data={visitMicrobiology} /> :
                        <p>No Data</p>
                    }
                </Panel>
                <Panel header={(<div>
                    <div style={{ fontSize: 20 }}>Microbiology Results Partially Complete</div>
                    <div style={{ fontSize: 14 }}>Total: {visitMicrobiologyResult?.length}</div>
                </div>
                )} key="2" style={panelStyle}>
                    {visitMicrobiologyResult?.length ?
                        <TableComponent data={visitMicrobiologyResult} /> :
                        <p>No Data</p>
                    }
                </Panel>
                <Panel header={(<div>
                    <div style={{ fontSize: 20 }}>Microbiology Results Complete</div>
                    <div style={{ fontSize: 14 }}>Total: {visitMicrobiologyComplete?.length}</div>
                </div>
                )} key="3" style={panelStyle}>
                    {visitMicrobiologyComplete?.length ?
                        <TableComponent data={visitMicrobiologyComplete} /> :
                        <p>No Data</p>
                    }
                </Panel>
            </Collapse>
        </>
    )
}