import { useState, useEffect, useRef } from 'react';
import { FilterOutlined } from '@ant-design/icons';
import { FilterWrapper } from './style';
import { Dropdown, DatePicker, Radio, Space, Button, Checkbox } from 'antd';
import { Services } from '../../../../__services';
import { CONSTANT } from '../../../../Utils';

const { RangePicker } = DatePicker;
const CheckboxGroup = Checkbox.Group;

const items = [
    {
        label: 'Date',
        key: '0',
    },
    {
        label: 'Location',
        key: '1',
    },
    {
        label: 'Health Worker',
        key: '2',
    },
    {
        label: 'Doctor',
        key: '3',
    },
];

export const Filter = ({ allLocation, onChange }) => {
    const [allHealthWorker, setAllHealthWorker] = useState([]);
    const [allDoctor, setAllDoctor] = useState([]);

    const [showDate, setShowDate] = useState(false);
    const [showLocation, setShowLocation] = useState(false);
    const [showHealthWorker, setShowHealthworker] = useState(false);
    const [showDoctor, setShowDoctor] = useState(false);

    const [dateValue, setDateValue] = useState();
    const [locationValue, setLocationValue] = useState();
    const [healthWorkerValue, setHealthWorkerValue] = useState();
    const [doctorValue, setDoctorValue] = useState();
    const isFirstRender = useRef(true);


    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false
            getHealthWorker();
            getDoctor();
            return;
        }
        // totalVisit();
    }, [allLocation])

    const getHealthWorker = () => {
        let params = `person_type_id=${CONSTANT.NURSE}`;
        try {
            Services.GetPersonWithParams(params).then(response => {
                if (response.rows) {
                    setAllHealthWorker(response.rows);
                }
            })
        } catch (e) {
            console.log('Fetch Health Worker Exception :', e)
        }
    }

    const getDoctor = () => {
        let params = `person_type_id=${CONSTANT.SYSTEMADMIN}`;
        try {
            Services.GetPersonWithParams(params).then(response => {
                if (response.rows) {
                    setAllDoctor(response.rows);
                }
            })
        } catch (e) {
            console.log('Fetch Doctor Exception :', e)
        }
    }

    const onClickHandler = (e) => {
        if (e.key === '0') {
            setShowDate(true);
            setShowLocation(false);
            setShowHealthworker(false);
            setShowDoctor(false);
        } else if (e.key === '1') {
            setShowLocation(true);
            setShowDate(false);
            setShowHealthworker(false);
            setShowDoctor(false);
        } else if (e.key === '2') {
            setShowHealthworker(true);
            setShowLocation(false);
            setShowDate(false);
            setShowDoctor(false);
        } else if (e.key === '3') {
            setShowDoctor(true);
            setShowDate(false);
            setShowLocation(false);
            setShowHealthworker(false);
        }
    }

    const onChangeHandler = (type, value) => {
        if (type === 'date') {
            setDateValue(value);
            onChange('filter', value, type);
        } else if (type === 'location') {
            setLocationValue(value);
            onChange('filter', value, type);
        } else if (type === 'healthWorker') {
            setHealthWorkerValue(value);
            onChange('filter', value, type);
        } else if (type === 'doctor') {
            setDoctorValue(value);
            onChange('filter', value, type);
        }
    }

    return (
        <FilterWrapper>
            <Dropdown menu={{ items, onClick: onClickHandler }} trigger={['click']}>
                <Button>
                    <FilterOutlined />
                    Add Filter
                </Button>
            </Dropdown>
            {showDate ? <RangePicker onChange={(e, value) => onChangeHandler('date', value)} /> :
                showLocation ? <CheckboxGroup value={locationValue} onChange={(value) => onChangeHandler('location', value)} >
                    {allLocation.map(location => <Checkbox key={location.id} value={location.id}>{location.name}</Checkbox>)}
                </CheckboxGroup>
                    :
                    showHealthWorker ? <Radio.Group onChange={(e) => onChangeHandler('healthWorker', e.target.value)} value={healthWorkerValue}>
                        <Space>
                            {allHealthWorker.map(healthWorker => <Radio key={healthWorker.id} value={healthWorker.id}>{healthWorker.first_name} {healthWorker.last_name}</Radio>)}
                        </Space>
                    </Radio.Group> :
                        showDoctor ? <Radio.Group onChange={(e) => onChangeHandler('doctor', e.target.value)} value={doctorValue}>
                            <Space >
                                {allDoctor.map(doctor => <Radio key={doctor.id} value={doctor.id}>{doctor.first_name} {doctor.last_name}</Radio>)}
                            </Space>
                        </Radio.Group>
                            : null
            }
        </FilterWrapper>
    )
}