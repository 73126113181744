import React from 'react';
import { InboxOutlined } from '@ant-design/icons';
import { message, Upload } from 'antd';
const { Dragger } = Upload;


export const DragAndDrop = ({ onChange, disabled }) => {
    return (
        <div style={{height: '200px', width: '500px'}}>
            <Dragger
                disabled={!disabled}
                // accept="image/png, image/jpeg, */dicom,.dcm, image/dcm, */dcm, .dicom"
                name='file'
                multiple={false}
                listType="picture"
                beforeUpload={() => { return false; }}
                onChange={(info) => {
                    const { status } = info.file;
                    if (status !== 'uploading') {
                        onChange('image', info.fileList, status);
                    } else if (status === 'error') {
                        message.error(`${info.file.name} file upload failed.`);
                    }
                }}
                onDrop={(e) => {
                    onChange('image', e.dataTransfer.files);
                }}>
                <p className="ant-upload-drag-icon">
                    <InboxOutlined/>
                </p>
                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                <p className="ant-upload-hint">
                    Support for a single or bulk upload. Strictly prohibited from uploading company data or other
                    banned files.
                </p>
            </Dragger >
        </div>
    )
}