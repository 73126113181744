import * as AuthHelper from './APIHelper';

export const GetPatient = () => {
    return AuthHelper.getResponse('/patient');
}

export const GetPatientWithParams = (params) => {
    return AuthHelper.getResponse(`/patient?${params}`);
}

export const PostPatient = (payload) => {
    return AuthHelper.postResponse('/patient', payload);
}

export const PutPatient = (id, payload) => {
    return AuthHelper.putResponse(`/patient/${id}`, payload);
}

export const DeletePatient = (id) => {
    return AuthHelper.deleteResponse(`/patient/${id}`);
}