import styled from "styled-components"; 

export const PhotoWrapper = styled.div`
    display: flex;
    gap: 5%;
    justify-content: center;
`;

export const PhotoSection = styled.div`
    text-align: center;
`;