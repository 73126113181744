import { useState, useRef, useEffect } from "react";
import { Input, Select, Space, DatePicker } from "antd";
import { Services } from "../../../__services";
import dayjs from 'dayjs';

export const AddEditUser = ({ first_name, middle_name, last_name, email, username, password, confirm_password, birthdate, gender, location, role, onChange }) => {
    const [allLocation, setAllLocation] = useState([]);
    const [allRole, setAllRole] = useState([]);
    const isFirstRender = useRef(true);

    useEffect(() => {
        if (isFirstRender.current) {
            getAllLocation();
            getAllRole();
            isFirstRender.current = false
            return;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getAllLocation = async () => {
        try {
            let location = await Services.GetLocation();
            setAllLocation(location.rows);
        } catch (err) {
            console.log('Fetch Location Exception :', err);
        }
    }

    const getAllRole = async () => {
        try {
            let role = await Services.GetRole();
            setAllRole(role.rows);
        } catch (err) {
            console.log('Fetch Person Type Exception :', err);
        }
    }

    return (
        <>
            <Space direction="vertical" style={{ width: '100%' }}>
                <Input value={first_name} onChange={e => onChange('first_name', e.target.value)} placeholder="First Name" />
                <Input value={middle_name} onChange={e => onChange('middle_name', e.target.value)} placeholder="Middle Name" />
                <Input value={last_name} onChange={e => onChange('last_name', e.target.value)} placeholder="Last Name" />
                <Input type="email" value={email} onChange={e => onChange('email', e.target.value)} placeholder="Email" />
                <Input value={username} onChange={e => onChange('username', e.target.value)} placeholder="User Name" />
                <div style={{ display: 'flex', gap: '5%' }}>
                    <Input type="password" value={password} onChange={e => onChange('password', e.target.value)} placeholder="Password" />
                    <Input type="password" value={confirm_password} onChange={e => onChange('confirm_password', e.target.value)} placeholder="Confirm Password" />
                </div>
                <div style={{ display: 'flex', gap: '5%' }}>
                    <DatePicker style={{ width: '94%' }} defaultValue={dayjs(birthdate)} format="YYYY-MM-DD" onChange={(e, format) => onChange('birthdate', format)} />
                    <Select value={gender} style={{ width: '100%' }} placeholder="Gender" onChange={(e) => onChange('gender', e)} options={[{ value: 'male', label: 'Male' }, { value: 'female', label: 'Female' }]} />
                </div>
                <div style={{ display: 'flex', gap: '5%' }}>
                    <Select value={location} style={{ width: '100%' }} placeholder="Location" onChange={(e) => onChange('location', e)} options={allLocation.map(location => { return { key: location.id, value: location.id, label: location.name } })} />
                    <Select
                        placeholder="Roles"
                        mode="multiple"
                        value={role}
                        style={{ width: '100%' }}
                        onChange={(value) => onChange('role', value)}
                        options={allRole.map(pt => { return { key: pt.id, value: pt.id, label: pt.name } })}
                    />
                </div>

            </Space>
        </>
    )
}