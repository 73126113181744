import styled from 'styled-components';

export const UploadImgeWrapper = styled.div`
    display: flex;
    gap: 5%;
`;

export const PhotoWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const PhotoSection = styled.div`
    width: 45%;
`;

export const PhotoContent = styled.div`
    display: flex;
    align-items: flex-end;
    height: 100px;
    align-content: center;
    flex-direction: column;
    gap: 1.5rem;
`;

export const PhotoCardWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    background: rgb(240, 240, 240);
    align-items: center;
    padding: 0px 10px;
    margin: 5px 0px;
    border-radius: 5px;
`;

export const PreviewCard = styled.div`
    cursor: pointer;
    height: 100px;
    align-content: center;
    text-decoration: underline;
    color: blue;
`;


export const IframeWrapper = styled.div`
    position: absolute;
    z-index: 9999999999999;
    top: 0px;
    right: 0px;
    width: 100%;
    height: 100%;
    background: white;

    & > div > span {
        float: right;
        padding: 10px;
        cursor: pointer;
    }

    & > iframe {
        width: 100%;
        height: 100%;
    }
`;