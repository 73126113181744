import CryptoJS from "crypto-js";

/**
 * Encrypt data before sending an AJAX call
 * @param {*} data 
 * @returns 
 */
export const Encryption = (data) => {
	return { data: CryptoJS.AES.encrypt(data, 'Visilant98!').toString() };
}

/**
 * Decrypt data received from AJAX call
 * @param {*} data 
 * @returns 
 */
export const Decryption = (data) => {
	return JSON.parse(CryptoJS.AES.decrypt(data, 'Visilant98!').toString(CryptoJS.enc.Utf8));
}

/**
 * Encrypt string values
 * @param {string} string 
 * @returns 
 */
export const EncryptionString = (string) => {
	return CryptoJS.AES.encrypt(string, 'Visilant98!').toString();
}

/**
 * 
 * @param {string} string 
 * @returns 
 */
export const DecryptionString = (string) => {
	return CryptoJS.AES.decrypt(string, 'Visilant98!').toString(CryptoJS.enc.Utf8);
}
