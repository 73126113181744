import styled from "styled-components";

export const EMRWrapper = styled.div`
    display: flex;
    gap: 4%;
`;

export const EMRSection = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;