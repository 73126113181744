import { useEffect, useRef, useState } from 'react';
import { PatientVolume, DiagnosisAnalysis, HumanResourceAnalysis } from './Pages';
import { Filter } from './Filter';
import { Services } from '../../../__services';
import { SpinnerComponent } from "../../../Component/Common";


export const Dashboard = () => {
    const [loading, setLoading] = useState(true);
    const [filterData, setFilterData] = useState();
    const [allLocation, setAllLocation] = useState([]);
    const isFirstRender = useRef(true);

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false
            getLocation();
            return;
        }
        // totalVisit();
    }, [])

    const getLocation = () => {
        try {
            Services.GetLocation().then(response => {
                if (response.rows) {
                    setAllLocation(response.rows);
                    setLoading(false);
                }
            })
        } catch (e) {
            console.log('Fetch Location Exception :', e)
        }
    }

    const onChangeHandler = (type, value, filterType) => {
        if (type === 'filter') {
            setFilterData({ [filterType]: value })
        }
    }

    return (
        loading ? <SpinnerComponent /> :
            <div>
                <Filter onChange={onChangeHandler} allLocation={allLocation} />
                <br />
                <PatientVolume filterData={filterData} allLocation={allLocation} />
                <br />
                <DiagnosisAnalysis filterData={filterData} allLocation={allLocation} />
                <br />
                <HumanResourceAnalysis filterData={filterData} allLocation={allLocation} />
            </div>
    )
}