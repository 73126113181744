import { Card } from "antd";
import { InfoCircleOutlined } from '@ant-design/icons';
import { calculateAge } from "../../../../Utils";


export const PatientInformation = ({ data }) => {

    return (
        <div>
            <Card title={(<>
                <InfoCircleOutlined /> Patient Information
            </>
            )}>
                <div style={{ display: 'flex', justifyContent: 'start', gap: 20 }}>
                    <div>
                        <b>Name:</b> {`${data.person.first_name} ${data.person.middle_name || ''} ${data.person.last_name}`}
                    </div>
                    <div>
                        <b>Age:</b> {calculateAge(data.person.birthdate)}
                    </div>
                    <div>
                        <b>Sex:</b> {data.person.gender}
                    </div>
                    <div>
                        <b>Visilant_Id:</b> {data.patient_identifier}
                    </div>
                </div>

            </Card>
        </div>
    )
}