import { CONSTANT } from '../../Utils';

export const processData = (datas = [], type) => {
    let newDatas = {
        awaitingConsult: [],
        visitInProgress: [],
        visitComplete: [],
        visitMicrobiology: [],
        visitMicrobiologyResult: [],
        visitMicrobiologyComplete: [],

        visitNeedsUpdate: [],
        visitUpdateComplete: [],
        visitFormComplete: []
    }
    return new Promise((resolve) => {
        if (datas.length) {
            datas.forEach((data, index) => {
                let { id, encounters, location, patient, obs } = data;
                if (encounters.length && patient) {
                    let typeOfVisit = 'awaitingConsult', encounter, adultEncounter;
                    if (type === 'user') {
                        let VISITCOMPLETE = encounters.find(enc => enc.encounter_type_id === CONSTANT.ENCOUNTERVISITCOMPLETE);
                        let VISITINPROGRESS = encounters.find(enc => enc.encounter_type_id === CONSTANT.ENCOUNTERVISITINPROGRESS);
                        let VISITADULTINITIAL = encounters.find(enc => enc.encounter_type_id === CONSTANT.ENCOUNTERADULTINITIAL);
                        if (VISITCOMPLETE) {
                            encounter = VISITCOMPLETE;
                            adultEncounter = VISITADULTINITIAL;
                            typeOfVisit = 'visitComplete';
                            let VISITNEEDSUPDATE = obs?.find(ob => ob?.obs_object?.value?.status === 'Paper Form Entry Needs Updates');
                            let VISITUPDATECOMPLETE = obs?.find(ob => ob?.obs_object?.value?.status === 'Update Complete, Needs Additional Review');
                            let VISITFORMCOMPLETE = obs?.find(ob => ob?.obs_object?.value?.status === 'Paper Form Entry Complete');
                            if (VISITFORMCOMPLETE) {
                                typeOfVisit = 'visitFormComplete';
                            } else if (VISITUPDATECOMPLETE) {
                                typeOfVisit = 'visitUpdateComplete';
                            } else if (VISITNEEDSUPDATE) {
                                typeOfVisit = 'visitNeedsUpdate';
                            }
                        }
                        else {
                            if (VISITINPROGRESS) {
                                encounter = VISITINPROGRESS
                                adultEncounter = VISITADULTINITIAL;
                                typeOfVisit = 'visitInProgress';
                            } else {
                                encounter = VISITADULTINITIAL;
                                adultEncounter = VISITADULTINITIAL;
                            }
                        }
                    } else {
                        let VISITMICROBIOLOGYCOMPLETE = encounters.find(enc => enc.encounter_type_id === CONSTANT.ENCOUNTERMICROBIOLOGYCOMPLETE);
                        let VISITMICROBIOLOGYRESULT = encounters.find(enc => enc.encounter_type_id === CONSTANT.ENCOUNTERMICROBIOLOGYRESULTS);
                        let VISITMICROBIOLOGY = encounters.find(enc => enc.encounter_type_id === CONSTANT.ENCOUNTERMICROBIOLOGY);
                        let VISITADULTINITIAL = encounters.find(enc => enc.encounter_type_id === CONSTANT.ENCOUNTERADULTINITIAL);
                        if (VISITMICROBIOLOGYCOMPLETE) {
                            encounter = VISITMICROBIOLOGYCOMPLETE;
                            adultEncounter = VISITADULTINITIAL;
                            typeOfVisit = 'visitMicrobiologyComplete';
                        } else if (VISITMICROBIOLOGYRESULT) {
                            encounter = VISITMICROBIOLOGYRESULT;
                            adultEncounter = VISITADULTINITIAL;
                            typeOfVisit = 'visitMicrobiologyResult';
                        } else if (VISITMICROBIOLOGY) {
                            encounter = VISITMICROBIOLOGY;
                            adultEncounter = VISITADULTINITIAL;
                            typeOfVisit = 'visitMicrobiology';
                        }
                    }
                    if (encounter) {
                        newDatas[typeOfVisit].push(
                            {
                                key: id,
                                patient_id: patient?.id,
                                patient_identifier: patient?.patient_identifier,
                                abha_no: patient?.abha_no,
                                name: `${patient.person.first_name} ${patient.person.middle_name || ''} ${patient.person.last_name}`,
                                gender: patient.person.gender,
                                dob: patient.person.birthdate,
                                created_at: adultEncounter?.created_at,
                                location: location.name,
                                provider: `${encounter.creator.first_name} ${encounter.creator.middle_name || ''} ${encounter.creator.last_name}`
                            }
                        )
                    }
                }
                if (index + 1 === datas.length) {
                    resolve(newDatas)
                }
            })
        } else {
            resolve(newDatas)
        }
    })
}