import * as AuthHelper from './APIHelper';

export const Signin = (payload) => {
    return AuthHelper.postResponse('/signin', payload);
}

export const CheckSession = () => {
    return AuthHelper.getResponse('/signin');
}

export const GetFailedLogin = (params) => {
    return AuthHelper.getResponse(`/login?${params}`)
}

export const PostFailedLogin = (payload) => {
    return AuthHelper.postResponse(`/login`, payload);
}

export const PutFailedLogin = (id, payload) => {
    return AuthHelper.putResponse(`/login/${id}`, payload)
}