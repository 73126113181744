import { Card, Checkbox, DatePicker, Alert, Input, Select, Space, notification } from "antd";
import { DeleteFilled, CloseOutlined } from '@ant-design/icons';
import { useRef, useState, useEffect } from "react";
import { CONSTANT, getFromLocalStorage } from "../../../../Utils";
import { Services } from "../../../../__services";
import { ResultWrapper } from './style';

let smear = ['NEGATIVE (No microbes identified on smear)', 'Gram positive bacilli', 'Gram negative bacilli',
    'Gram negative cocci', 'Gram positive cocci', 'Fungal filaments', 'Fungal yeast', 'Acanthamoeba', 'Nocardia', 'Other smear result'];

// let smearTypeList = ['Gram stain only', 'K-OH stain only', 'Gram and KOH stain', 'Other smear'];

let bacterialCulture = ['NEGATIVE (No bacterial growth)', 'Bacillus spp', 'Corynebacterium diptheriae',
    'Enterococcus faecalis', 'Enterococcus faecium', 'Gordonia sputi', 'Gordonia spp other', 'Other Gram Positive species',
    'Kocuria palustris', 'Kocuria rosea', 'Kocuria spp other', 'Mycobacteria spp', 'Mycobacterium  chelonae',
    'Mycobacterium abscessus', 'Mycobacterium fortuitum', 'Nocardia caviae', 'Nocardia abscessu',
    'Nocardia brasiliensis', 'Nocardia farcinica', 'Nocardia levis', 'Nocardia nova comple', 'Nocardia spp other',
    'Nocardia asteroides', 'Staphylococcus aureus', 'Staphylococcus epidermidis', 'Staphylococcus, coagulase negative',
    'Streptococcus pneumoniae', 'Streptococcus pyogens', 'Streptococcus viridans group', 'Viridans streptococci',
    'Acinetobacter baumannii', 'Acinetobacter junii', 'Acinetobacter spp other',
    'Achromobacter xylosoxidan', 'Achromobacter spp other',
    'Chryseobacterium meningosepticum/Flavobacterium meningosepticum/Elizabethkingia meningosepticum', 'Chryseobacterium spp -ther',
    'Delftia acidovorans', 'Delftia spp other', 'Enterobacter spp other', 'Escherichia coli', 'Haemophilus influenzae',
    'Klebsiella species', 'Moraxella spp', 'Providencia rettgeri', 'Providencia spp other', 'Pseudomonas aeruginosa', 'Pseudomonas species',
    'Pseudomonas spp (non-aeruginosa)', 'Serratia marcescens', 'Other Gram negative species'];

let fungalCulture = ['NEGATIVE (No fungal growth)', 'Acremonium spp', 'Alternaria alternata', 'Alternaria infectoria',
    'Alternaria tenuissima', 'Alternaria spp other', 'Arthrographis kalrae', 'Aspergillus flavus', 'Aspergillus fumigatus', 'Aspergillus niger',
    'Aspergillus terreus', 'Aspergillus spp other', 'Aureobasidium pullulans', 'Bipolaris hawaiiensis', 'Bipolaris spicifera',
    'Bipolaris spp other', 'Candida albicans', 'Chaetomium spp', 'Cladosporium tenuissimum', 'Cladosporium pseudocladosporioides', 'Cladosporium subuliforme',
    'Cladosporium spp-other', 'Colletotrichum coccoides', 'Colletotrichum crassipes', 'Colletotrichum dematium',
    'Colletotrichum gloeosporioides', 'Colletotrichum graminicola', 'Colletotrichum spp -other', 'Corynespora cassiicola', 'Curvularia lunata',
    'Curvularia senegalensis', 'Curvularia spp other', 'Exerohilum spp', 'Exophiala phaeomuriformis', 'Fusarium spp', 'Lasiodiplodia species',
    'Lichtheimia corymbifera', 'Microsphaeropsis olivacea', 'Microsporidia', 'Papulaspora equi', 'Pseudoallescheria boydii', 'Purpureocillium lilacinum',
    'Pythium aphanidermatum', 'Pythium insidiosum', 'Pythium spp other', 'Scedosporium apiospermum',
    'Scedosporium spp other', 'Schizophyllum commune', 'Trametes betulina', 'UID- Unidentified dematiaceous fungus', 'UIH- Unidentified hyaline fungus',
    'Wickerhamomyces anomalus', 'Other fungus'];

let anthamoeba = ['NEGATIVE (No Acanthamoeba identified)', 'Acanthamoeba', 'Other test result']

export const Microbiologic = ({ data, submitCoordinator, clickHandler }) => {
    const [api, contextHolder] = notification.useNotification();
    const [selectedCheckbox, setSelectedCheckbox] = useState([]);

    const [kohDate, setKohDate] = useState();
    // const [smearType, setSmearType] = useState('');
    // const [kohOther, setKohOther] = useState();
    const [kohResult, setKohResult] = useState([]);
    const [kohOtherResult, setKohOtherResult] = useState();

    const [gramDate, setGramDate] = useState();
    // const [gramOther, setGramOther] = useState();
    const [gramResult, setGramResult] = useState([]);
    const [gramOtherResult, setGramOtherResult] = useState();

    const [bacterialDate, setBacterialDate] = useState();
    const [bacterialResult, setBacterialResult] = useState([]);
    const [bacterialOtherResult, setBacterialOtherResult] = useState();

    const [fungalDate, setFungalDate] = useState();
    const [fungalResult, setFungalResult] = useState([]);
    const [fungalOtherResult, setFungalOtherResult] = useState();

    const [anthamoebaDate, setAnthamoebaDate] = useState();
    const [anthamoebaResult, setAnthamoebaResult] = useState([]);
    const [anthamoebaOtherResult, setAnthamoebaOtherResult] = useState();

    const [microbiologicResults, setMicrobiologicResults] = useState([]);

    const [otherDate, setOtherDate] = useState();
    const [otherInputType, setOtherInput] = useState('');
    const [otherInputResult, setOtherResult] = useState('');

    const [kohOtherNote, setKohOtherNote] = useState();
    const [gramOtherNote, setGramOtherNote] = useState();
    const [bacterialOtherNote, setBacterialOtherNote] = useState();
    const [fungualOtherNote, setFungalOtherNote] = useState();
    const [anthamoebaOtherNote, setAnthamoebaOtherNote] = useState();
    const [otherInputNote, setOtherInputNote] = useState();

    const isFirstRender = useRef(true);

    useEffect(() => {
        if (isFirstRender.current) {
            getMicrobiologic();
            isFirstRender.current = false
            return;
        }

        if (submitCoordinator === 'visitCompleteCoordinator' || submitCoordinator === 'visitNote') {
            submitMicrobiologic(submitCoordinator);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submitCoordinator])

    useEffect(() => {

    }, [selectedCheckbox])

    const openNotificationWithIcon = (type, title, description) => {
        api[type]({
            message: title,
            description,
        });
    };

    const onClickHandler = (type, value) => {
        if (type === 'deleteMicrobiologic') {
            deleteMicrobiologic(value);
        }
    }

    const onChangeHandler = (type, value, extraValue) => {
        if (type === 'checkbox') {
            let newCheckBox = [...selectedCheckbox];
            if (value) {
                newCheckBox.push(extraValue);
                setSelectedCheckbox(newCheckBox);
            } else {
                let index = newCheckBox.indexOf(extraValue);
                if (index !== -1) {
                    newCheckBox.splice(index, 1);
                    setSelectedCheckbox(newCheckBox);
                }
            }
        }
        // else if (type === 'smearType') {
        //     setSmearType(value);
        // } 
        else if (type === 'kohDate') {
            setKohDate(extraValue);
        } else if (type === 'gramDate') {
            setGramDate(extraValue);
        } else if (type === 'bacterialDate') {
            setBacterialDate(extraValue);
        } else if (type === 'fungalDate') {
            setFungalDate(extraValue);
        } else if (type === 'anthamoebaDate') {
            setAnthamoebaDate(extraValue);
        } else if (type === 'otherDate') {
            setOtherDate(extraValue);
        } else if (type === 'kohResult') {
            setKohResult(value);
        } else if (type === 'gramResult') {
            setGramResult(value);
        } else if (type === 'bacterialResult') {
            setBacterialResult(value);
        } else if (type === 'fungalResult') {
            setFungalResult(value);
        } else if (type === 'anthamoebaResult') {
            setAnthamoebaResult(value);
        }
    }

    const getMicrobiologic = () => {
        setMicrobiologicResults(data?.obs);
    }

    const submitMicrobiologic = async (type) => {
        let user = await getFromLocalStorage('user');
        let allData = [];
        let payload = {
            encounter_id: data.id,
            creator_id: user.user.person_id,
            patient_id: data.patient_id,
            visit_id: data.visit_id,
        }
        if (selectedCheckbox.includes(1)) {
            if (!kohResult.length && submitCoordinator !== 'visitNote' && kohDate) {
                openNotificationWithIcon('error', 'Results were not entered for Gram Stain test. Are you sure you want to submit? You can select "Save Incomplete" to input results when they are available');
                return;
            }
            allData.push({
                ...payload,
                concept_id: CONSTANT.COORDINATORKOHSTAIN,
                obs_string: {
                    value: `${kohDate} - ${kohResult.join(', ')} ${kohOtherResult ? `- ${kohOtherResult}` : ''} ${kohOtherNote ? `- ${kohOtherNote}` : ''}`
                }
            })
        } else {
            allData.push({
                ...payload,
                concept_id: CONSTANT.COORDINATORKOHSTAIN,
                obs_string: {
                    value: `KOH - Not performed`
                }
            })
        }
        if (selectedCheckbox.includes(2)) {
            if (!gramResult.length && submitCoordinator !== 'visitNote' && gramDate) {
                openNotificationWithIcon('error', 'Results were not entered for Gram Stain test. Are you sure you want to submit? You can select "Save Incomplete" to input results when they are available');
                return;
            }
            allData.push({
                ...payload,
                concept_id: CONSTANT.COORDINATORGRAMSTAIN,
                obs_string: {
                    value: `${gramDate} - ${gramResult.join(', ')} ${gramOtherResult ? `- ${gramOtherResult}` : ''} ${gramOtherNote ? `- ${gramOtherNote}` : ''}`
                }
            })
        } else {
            allData.push({
                ...payload,
                concept_id: CONSTANT.COORDINATORGRAMSTAIN,
                obs_string: {
                    value: `Gram Stain - Not Performed`
                }
            })
        }
        if (selectedCheckbox.includes(3)) {
            if (!bacterialResult.length && submitCoordinator !== 'visitNote' && bacterialDate) {
                openNotificationWithIcon('error', 'Results were not entered for Bacterial culture test. Are you sure you want to submit? You can select "Save Incomplete" to input results when they are available');
                return;
            }
            allData.push({
                ...payload,
                concept_id: CONSTANT.COORDINATORBACTERIALCULTURE,
                obs_string: {
                    value: `${bacterialDate} - ${bacterialResult.join(', ')} ${bacterialOtherResult ? `- ${bacterialOtherResult}` : ''} ${bacterialOtherNote ? `- ${bacterialOtherNote}` : ''}`
                }
            })
        } else {
            allData.push({
                ...payload,
                concept_id: CONSTANT.COORDINATORBACTERIALCULTURE,
                obs_string: {
                    value: `Bacterial culture - Not Performed`
                }
            })
        }
        if (selectedCheckbox.includes(4)) {
            if (!fungalResult.length && submitCoordinator !== 'visitNote' && fungalDate) {
                openNotificationWithIcon('error', 'Results were not entered for Fungal culture test. Are you sure you want to submit? You can select "Save Incomplete" to input results when they are available');
                return;
            }
            allData.push({
                ...payload,
                concept_id: CONSTANT.COORDINATORFUNGALCULTURE,
                obs_string: {
                    value: `${fungalDate} - ${fungalResult.join(', ')} ${fungalOtherResult ? `- ${fungalOtherResult}` : ''} ${fungualOtherNote ? `- ${fungualOtherNote}` : ''}`
                }
            })
        } else {
            allData.push({
                ...payload,
                concept_id: CONSTANT.COORDINATORFUNGALCULTURE,
                obs_string: {
                    value: `Fungal culture - Not Performed`
                }
            })
        }
        if (selectedCheckbox.includes(5)) {
            if (!anthamoebaResult.length && submitCoordinator !== 'visitNote' && anthamoebaDate) {
                openNotificationWithIcon('error', 'Results were not entered for Acanthamoeba culture test. Are you sure you want to submit? You can select "Save Incomplete" to input results when they are available');
                return;
            }
            allData.push({
                ...payload,
                concept_id: CONSTANT.COORDINATORACANTHAMOEBA,
                obs_string: {
                    value: `${anthamoebaDate} - ${anthamoebaResult.join(', ')} ${anthamoebaOtherResult ? `- ${anthamoebaOtherResult}` : ''} ${anthamoebaOtherNote ? `- ${anthamoebaOtherNote}` : ''}`
                }
            })
        } else {
            allData.push({
                ...payload,
                concept_id: CONSTANT.COORDINATORACANTHAMOEBA,
                obs_string: {
                    value: `Acanthamoeba culture - Not Performed`
                }
            })
        }
        if (selectedCheckbox.includes(6)) {
            if (!otherInputType && !otherInputResult && submitCoordinator !== 'visitNote') {
                openNotificationWithIcon('error', 'Results were not entered for test. Are you sure you want to submit? You can select "Save Incomplete" to input results when they are available');
                return;
            }
            allData.push({
                ...payload,
                concept_id: CONSTANT.COORDINATOROTHER,
                obs_string: {
                    value: `${otherDate} - ${otherInputType} ${otherInputResult ? `- ${otherInputResult}` : ''} ${otherInputNote ? `- ${otherInputNote}` : ''}`
                }
            })
        } else {
            allData.push({
                ...payload,
                concept_id: CONSTANT.COORDINATOROTHER,
                obs_string: {
                    value: `Other microbiologic testing - Not Performed`
                }
            })
        }
        if (otherInputResult !== '') {
            allData.push({
                ...payload,
                concept_id: CONSTANT.COORDINATOROTHER,
                obs_string: {
                    value: `${otherInputResult}`
                }
            })
        }
        if (allData.length) {
            Services.PostObs(allData).then(response => {
                if (response.status !== 400) {
                    resetField();
                    setMicrobiologicResults([...microbiologicResults, ...response])
                    openNotificationWithIcon('success', 'Microbioloic Result Submitted', '');
                }
                if (type === 'visitCompleteCoordinator') {
                    clickHandler('visitCompleteCoordinator', true)
                }
            })
        } else {
            openNotificationWithIcon('error', 'Nothing Selected');
        }
    }

    const deleteMicrobiologic = (id) => {
        Services.DeleteObs(id).then(response => {
            if (response.status !== 400 && response.affected) {
                let index = microbiologicResults.findIndex(dt => dt.id === id);
                if (index !== -1) {
                    let newData = [...microbiologicResults]
                    newData.splice(index, 1);
                    setMicrobiologicResults(newData);
                    openNotificationWithIcon('success', 'Microbiologic Result Deleted');
                }
            } else {
                openNotificationWithIcon('error', 'Server Error')
            }
        })
    }

    const resetField = () => {
        setKohDate('');
        setGramDate('');
        // setSmearType('');
        // setSmearOther('');
        setKohResult([]);
        setKohOtherResult('');
        setGramResult([]);
        setGramOtherResult('');
        setFungalDate('');
        setFungalResult([]);
        setFungalOtherResult('');
        setBacterialDate('');
        setBacterialResult([]);
        setBacterialOtherResult('');
        setAnthamoebaDate('');
        setAnthamoebaResult([]);
        setAnthamoebaOtherResult('');
        setOtherDate('');
        setSelectedCheckbox([]);
        setOtherInput('');
        setOtherResult('');

        setKohOtherNote('');
        setGramOtherNote('');
        setBacterialOtherNote('');
        setFungalOtherNote('');
        setOtherInputNote('');

    }


    return (
        <div>
            {data?.obs ? <Card title={(<>Microbiologic Testing Status</>)}>
                <Space direction="vertical" style={{ width: '100%' }}>
                    {/* <Checkbox checked={selectedCheckbox.includes(1) ? true : false} onChange={(e) => onChangeHandler('checkbox', e.target.checked, 1)}>Smear Performed?</Checkbox>
                    {selectedCheckbox.includes(1) ?
                        <>
                            <DatePicker style={{ width: 300 }} format="YYYY-MM-DD" onChange={(e, format) => onChangeHandler('smearDate', e, format)} />
                            <Select
                                showSearch
                                value={smearType}
                                style={{ width: 300 }}
                                placeholder="Smear Type"
                                optionFilterProp="children"
                                onChange={(e) => onChangeHandler('smearType', e)}
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                options={smearTypeList.map((smearT) => ({ label: smearT, value: smearT }))}
                            />
                            {smearType === 'Other smear' ? <Input placeholder="Enter Smear Type" style={{ width: 300 }} value={smearOther} onChange={e => setSmearOther(e.target.value)} /> : null}
                            <ResultWrapper>
                                <Select
                                    showSearch
                                    mode="multiple"
                                    disabled={smearResult.includes('NEGATIVE (No microbes identified on smear)')}
                                    value={smearResult}
                                    style={{ width: 300 }}
                                    placeholder="Results"
                                    optionFilterProp="children"
                                    onChange={(e) => onChangeHandler('smearResult', e)}
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={smear.map((smear) => ({ label: smear, value: smear }))}
                                />
                                {smearResult.includes('NEGATIVE (No microbes identified on smear)') ? <CloseOutlined onClick={() => setSmearResult([])} /> : null}
                            </ResultWrapper>
                            {smearResult.includes('Other smear result') ? <Input placeholder="Enter Other Result" value={smearOtherResult} onChange={(e) => setSmearOtherResult(e.target.value)} /> : null}
                        </>
                        : null} */}

                    <Checkbox checked={selectedCheckbox.includes(1) ? true : false} onChange={(e) => onChangeHandler('checkbox', e.target.checked, 1)}>KOH performed?</Checkbox>
                    {selectedCheckbox.includes(1) ?
                        <>
                            <DatePicker style={{ width: 300 }} format="YYYY-MM-DD" onChange={(e, format) => onChangeHandler('kohDate', e, format)} />
                            {/* <Select
                                showSearch
                                value={smearType}
                                style={{ width: 300 }}
                                placeholder="Smear Type"
                                optionFilterProp="children"
                                onChange={(e) => onChangeHandler('smearType', e)}
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                options={smearTypeList.map((smearT) => ({ label: smearT, value: smearT }))}
                            />
                            {smearType === 'Other smear' ? <Input placeholder="Enter Smear Type" style={{ width: 300 }} value={smearOther} onChange={e => setSmearOther(e.target.value)} /> : null} */}
                            <ResultWrapper>
                                <Select
                                    showSearch
                                    mode="multiple"
                                    disabled={kohResult.includes('NEGATIVE (No microbes identified on smear)')}
                                    value={kohResult}
                                    style={{ width: 300 }}
                                    placeholder="Results"
                                    optionFilterProp="children"
                                    onChange={(e) => onChangeHandler('kohResult', e)}
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={smear.map((smear) => ({ label: smear, value: smear }))}
                                />
                                {kohResult.includes('NEGATIVE (No microbes identified on smear)') ? <CloseOutlined onClick={() => setKohResult([])} /> : null}
                                {kohResult.includes('Other smear result') ? <Input style={{ width: 300 }} placeholder="Enter Other Result" value={kohOtherResult} onChange={(e) => setKohOtherResult(e.target.value)} /> : null}
                            </ResultWrapper>
                            <Input style={{ width: 300 }} placeholder="Other notes" value={kohOtherNote} onChange={(e) => setKohOtherNote(e.target.value)} />
                        </>
                        : null}

                    <Checkbox checked={selectedCheckbox.includes(2) ? true : false} onChange={(e) => onChangeHandler('checkbox', e.target.checked, 2)}>Gram Stain Performed?</Checkbox>
                    {selectedCheckbox.includes(2) ?
                        <>
                            <DatePicker style={{ width: 300 }} format="YYYY-MM-DD" onChange={(e, format) => onChangeHandler('gramDate', e, format)} />
                            {/* <Select
                                showSearch
                                value={smearType}
                                style={{ width: 300 }}
                                placeholder="Smear Type"
                                optionFilterProp="children"
                                onChange={(e) => onChangeHandler('smearType', e)}
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                options={smearTypeList.map((smearT) => ({ label: smearT, value: smearT }))}
                            />
                            {smearType === 'Other smear' ? <Input placeholder="Enter Smear Type" style={{ width: 300 }} value={smearOther} onChange={e => setSmearOther(e.target.value)} /> : null} */}
                            <ResultWrapper>
                                <Select
                                    showSearch
                                    mode="multiple"
                                    disabled={gramResult.includes('NEGATIVE (No microbes identified on smear)')}
                                    value={gramResult}
                                    style={{ width: 300 }}
                                    placeholder="Results"
                                    optionFilterProp="children"
                                    onChange={(e) => onChangeHandler('gramResult', e)}
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={smear.map((smear) => ({ label: smear, value: smear }))}
                                />
                                {gramResult.includes('NEGATIVE (No microbes identified on smear)') ? <CloseOutlined onClick={() => setGramResult([])} /> : null}
                                {gramResult.includes('Other smear result') ? <Input style={{ width: 300 }} placeholder="Enter Other Result" value={gramOtherResult} onChange={(e) => setGramOtherResult(e.target.value)} /> : null}
                            </ResultWrapper>
                            <Input style={{ width: 300 }} placeholder="Other notes" value={gramOtherNote} onChange={(e) => setGramOtherNote(e.target.value)} />
                        </>
                        : null}
                    <Checkbox checked={selectedCheckbox.includes(3) ? true : false} onChange={(e) => onChangeHandler('checkbox', e.target.checked, 3)}>Bacterial culture performed?</Checkbox>
                    {selectedCheckbox.includes(3) ?
                        <>
                            <DatePicker style={{ width: 300 }} format="YYYY-MM-DD" onChange={(e, format) => onChangeHandler('bacterialDate', e, format)} />
                            <ResultWrapper>
                                <Select
                                    showSearch
                                    mode="multiple"
                                    disabled={bacterialResult.includes('NEGATIVE (No bacterial growth)')}
                                    value={bacterialResult}
                                    style={{ width: 300 }}
                                    placeholder="Results"
                                    optionFilterProp="children"
                                    onChange={(e) => onChangeHandler('bacterialResult', e)}
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={bacterialCulture.map((bact) => ({ label: bact, value: bact }))}
                                />
                                {bacterialResult.includes('NEGATIVE (No bacterial growth)') ? <CloseOutlined onClick={() => setBacterialResult([])} /> : null}
                                {bacterialResult.includes('Other Gram negative species') || bacterialResult.includes('Other Gram Positive species') ? <Input style={{ width: 300 }} placeholder="Enter Other Result" value={bacterialOtherResult} onChange={(e) => setBacterialOtherResult(e.target.value)} /> : null}
                            </ResultWrapper>
                            <Input style={{ width: 300 }} placeholder="Other notes" value={bacterialOtherNote} onChange={(e) => setBacterialOtherNote(e.target.value)} />
                        </>
                        : null}
                    <Checkbox checked={selectedCheckbox.includes(4) ? true : false} onChange={(e) => onChangeHandler('checkbox', e.target.checked, 4)}>Fungal culture performed?</Checkbox>
                    {selectedCheckbox.includes(4) ?
                        <>
                            <DatePicker style={{ width: 300 }} format="YYYY-MM-DD" onChange={(e, format) => onChangeHandler('fungalDate', e, format)} />
                            <ResultWrapper>
                                <Select
                                    showSearch
                                    mode="multiple"
                                    disabled={fungalResult.includes('NEGATIVE (No fungal growth)')}
                                    value={fungalResult}
                                    style={{ width: 300 }}
                                    placeholder="Results"
                                    optionFilterProp="children"
                                    onChange={(e) => onChangeHandler('fungalResult', e)}
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={fungalCulture.map((fungal) => ({ label: fungal, value: fungal }))}
                                />
                                {fungalResult.includes('NEGATIVE (No fungal growth)') ? <CloseOutlined onClick={() => setFungalResult([])} /> : null}
                                {fungalResult.includes('Other fungus') ? <Input style={{ width: 300 }} placeholder="Enter Other Result" value={fungalOtherResult} onChange={(e) => setFungalOtherResult(e.target.value)} /> : null}
                            </ResultWrapper>
                            <Input style={{ width: 300 }} placeholder="Other notes" value={fungualOtherNote} onChange={(e) => setFungalOtherNote(e.target.value)} />
                        </>
                        : null}
                    <Checkbox checked={selectedCheckbox.includes(5) ? true : false} onChange={(e) => onChangeHandler('checkbox', e.target.checked, 5)}>Acanthamoeba culture performed?</Checkbox>
                    {selectedCheckbox.includes(5) ?
                        <>
                            <DatePicker style={{ width: 300 }} format="YYYY-MM-DD" onChange={(e, format) => onChangeHandler('anthamoebaDate', e, format)} />
                            <ResultWrapper>
                                <Select
                                    showSearch
                                    mode="multiple"
                                    disabled={anthamoebaResult.includes('NEGATIVE (No Acanthamoeba identified)')}
                                    value={anthamoebaResult}
                                    style={{ width: 300 }}
                                    placeholder="Results"
                                    optionFilterProp="children"
                                    onChange={(e) => onChangeHandler('anthamoebaResult', e)}
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={anthamoeba.map((ant) => ({ label: ant, value: ant }))}
                                />
                                {anthamoebaResult.includes('Other test result') ? <Input style={{ width: 300 }} placeholder="Enter Other Result" value={anthamoebaOtherResult} onChange={(e) => setAnthamoebaOtherResult(e.target.value)} /> : null}
                            </ResultWrapper>
                            <Input style={{ width: 300 }} placeholder="Other notes" value={anthamoebaOtherNote} onChange={(e) => setAnthamoebaOtherNote(e.target.value)} />
                        </>
                        : null}
                    <Checkbox checked={selectedCheckbox.includes(6) ? true : false} onChange={(e) => onChangeHandler('checkbox', e.target.checked, 6)}>Other microbiologic testing performed?</Checkbox>
                    {selectedCheckbox.includes(6) ?
                        <>
                            <DatePicker style={{ width: 300 }} format="YYYY-MM-DD" onChange={(e, format) => onChangeHandler('otherDate', e, format)} />
                            <Input style={{ width: 300 }} placeholder="Test type" value={otherInputType} onChange={e => setOtherInput(e.target.value)} />
                            <Input style={{ width: 300 }} placeholder="Test result" value={otherInputResult} onChange={e => setOtherResult(e.target.value)} />
                            <Input style={{ width: 300 }} placeholder="Other notes" value={otherInputNote} onChange={(e) => setOtherInputNote(e.target.value)} />
                        </>
                        : null}
                    <Checkbox checked={selectedCheckbox.includes(6) ? true : false} onChange={(e) => {
                        onChangeHandler('checkbox', e.target.checked, 6);
                        e.target.checked ? setOtherResult('No testing performed') : setOtherResult('')
                    }}>No testing performed</Checkbox>
                </Space>
                {microbiologicResults.map(medical => <div key={medical.id}><br /><Alert type="info" message={
                    (
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div>{medical.obs_string.value}</div>
                            <DeleteFilled onClick={() => onClickHandler('deleteMicrobiologic', medical.id)} style={{ color: 'red' }} />
                        </div>)
                } /></div>
                )}
            </Card> : null}
            {contextHolder}
        </div>
    )
}