import styled from "styled-components";

export const SubmitWrapper = styled.div`
    text-align: center;
    background: white;
    padding: 10px;
    position: sticky;
    bottom: 36px;
    z-index: 999;
`;


export const ChatWrapper = styled.div`
    position: fixed;
    bottom: 35px;
    z-index: 99999;
    right: 60px;
`;