import React, { Component } from 'react';
import { saveToLocalStorage, getFromLocalStorage } from '../Utils';

const Context = React.createContext()
const { Provider, Consumer } = Context;
export { Context, Consumer }

export class ContextProvider extends Component {
    state = {
        user: undefined,
        page: '/',
        allLocation: []
    }

    componentDidMount() {
        let user = getFromLocalStorage('user');
        let page = getFromLocalStorage('page');
        if (user) {
            this.setState({ user })
        }
        if (page) {
            this.setState({ page })
        }
    }

    updateState = (type, value) => {
        this.setState({ [type]: value }, () => {
            if (type === 'user') {
                saveToLocalStorage('user', value);
            } else if (type === 'page') {
                saveToLocalStorage('page', value);
            }
        })
    }

    render() {
        let { state, updateState } = this;
        return (
            <>
                <Provider value={{ state, updateState }}>
                    {this.props.children}
                </Provider>
            </>
        )
    }
}

export default ContextProvider;