import { useState, useRef, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { PatientInformation } from './PatientInformation';
import { MedicalHistory } from './MedicalHistory';
import { InfectionHistory } from './InfectionHistory';
import { Diagnosis } from './Diagnosis';
import { EMR } from './EMR';
import { OnExamination } from './OnExamination';
import { SlitLampFinding } from './SlitLampFinding';
import { FollowUp } from './FollowUp';
import { Button } from 'antd';
import { CONSTANT, Server, getFromLocalStorage } from '../../../Utils';
import { SlitLampFindingPhoto } from './SiltLampFindingPhoto';
import { SubmitWrapper, ChatWrapper } from './style';
import { Services } from '../../../__services';
import { ModalComponent } from '../../../Component/Common';
import { CommentBot } from '../../../Component/CommentBot';
import { WechatOutlined } from '@ant-design/icons';

let fixConcepts = [
    { name: 'Visual Acuity Right', value: CONSTANT.VARIGHT }, { name: 'Visual Acuity Left', value: CONSTANT.VALEFT }, { name: 'Pinhole Right', value: CONSTANT.PINEHOLERIGHT },
    { name: 'Pinhole Left', value: CONSTANT.PINEHOLELEFT }, { name: 'VA mesurement right', value: CONSTANT.EMRVAMEASUREMENTRIGHT }, { name: 'VA mesurement left', value: CONSTANT.EMRVAMEASUREMENTLEFT },
    { name: 'LacrimalDuct Right', value: CONSTANT.EMRLACRIMALDUCTRIGHT }, { name: 'LacrimalDuct Left', value: CONSTANT.EMRLACRIMALDUCTLEFT }, { name: 'IOP Right', value: CONSTANT.EMRIOPRIGHT },
    { name: 'IOP left', value: CONSTANT.EMRIOPLEFT }, { name: 'Right Eye lens', value: CONSTANT.DIAGNOSISRIGHTEYELENS }, { name: 'Left Eye lens', value: CONSTANT.DIAGNOSISLEFTEYELENS },
    { name: 'Right Eye Additional Pathalogy', value: CONSTANT.DIAGNOSISRIGHTEYEADDITIONALPATHALOGY }, { name: 'Left Eye Additional Pathalogy', value: CONSTANT.DIAGNOSISLEFTEYEADDITIONALPATHALOGY },
    { name: 'Right Eye Corneal', value: CONSTANT.DIAGNOSISRIGHTEYECORNEAL }, { name: 'Left Eye Corneal', value: CONSTANT.DIAGNOSISLEFTEYECORNEAL }, { name: 'Corneal Infection', value: CONSTANT.DIAGNOSISCOREALINFECTION },
    { name: 'Follow Up', value: CONSTANT.INFECTIONHISTORYRIGHTFOLLOWUP }
]

let cornealConceptsRight = [
    { name: 'Epithelial - Right', value: CONSTANT.INFECTIONHISTORYRIGHTEPITHELIAL }, { name: 'Working Diagnosis - Right', value: CONSTANT.INFECTIONHISTORYRIGHTWORKING },
    { name: 'Depth - Right', value: CONSTANT.INFECTIONHISTORYRIGHTDEPTH },
    { name: 'Specific exam - Right', value: CONSTANT.INFECTIONHISTORYRIGHTSPECIFIC }, { name: 'Hypopyon - Right', value: CONSTANT.INFECTIONHISTORYRIGHTHYPOPYON },
    { name: 'Microbiology - Right', value: CONSTANT.INFECTIONHISTORYRIGHTMICROBIOLOGY }, { name: 'Centerally - Right', value: CONSTANT.INFECTIONHISTORYRIGHTCENTERALLY },
    { name: 'Diameter - Right', value: CONSTANT.INFECTIONHISTORYRIGHTDIAMETER }, { name: 'LIMBUS - Right', value: CONSTANT.INFECTIONHISTORYRIGHTLIMBUS },
    { name: 'Procedure - Right', value: CONSTANT.INFECTIONHISTORYRIGHTPROCEDURE },
    { name: 'Medical History - Right', value: CONSTANT.MEDICALHISTORYRIGHT },
]

let notImgRight = [
    { name: 'Stromal - Right', value: CONSTANT.INFECTIONHISTORYRIGHTSTROMAL },
]

let cornealConceptsLeft = [
    { name: 'Epithelial - Left', value: CONSTANT.INFECTIONHISTORYLEFTEPITHELIAL }, { name: 'Working Diagnosis - Left', value: CONSTANT.INFECTIONHISTORYLEFTWORKING },
    { name: 'Depth - Left', value: CONSTANT.INFECTIONHISTORYLEFTDEPTH },
    { name: 'Specific exam - Left', value: CONSTANT.INFECTIONHISTORYLEFTSPECIFIC }, { name: 'Hypopyon - Left', value: CONSTANT.INFECTIONHISTORYLEFTHYPOPYON },
    { name: 'Microbiology - Left', value: CONSTANT.INFECTIONHISTORYLEFTMICROBIOLOGY }, { name: 'Centerally  - Left', value: CONSTANT.INFECTIONHISTORYLEFTCENTERALLY },
    { name: 'Diameter - Left', value: CONSTANT.INFECTIONHISTORYLEFTDIAMETER }, { name: 'LIMBUS - Left', value: CONSTANT.INFECTIONHISTORYLEFTLIMBUS },
    { name: 'Procedure - Left', value: CONSTANT.INFECTIONHISTORYLEFTPROCEDURE },
    { name: 'Medical History - Left', value: CONSTANT.MEDICALHISTORYLEFT },
]

let notImgLeft = [
    { name: 'Stromal - Left', value: CONSTANT.INFECTIONHISTORYLEFTSTROMAL },
]

let therapeuticConceptRight = [{ name: 'Therapeutic - Right', value: CONSTANT.INFECTIONHISTORYRIGHTTHERAPEUTIC }]
let therapeuticConceptLeft = [{ name: 'Therapeutic - Left', value: CONSTANT.INFECTIONHISTORYLEFTTHERAPEUTIC }]

let nestedConceptsRight = [
    { name: 'Presentation Days - Right', value: CONSTANT.INFECTIONHISTORYRIGHTPRESENTATIONDATE },
    { name: 'Symptoms Days - Right', value: CONSTANT.INFECTIONHISTORYRIGHTSYMPTOMSDAYS },
    { name: 'Risk factor - Right', value: CONSTANT.INFECTIONHISTORYRIGHTRISKFACTOR }
]

let nestedConceptsLeft = [
    { name: 'Presentation Days - Left', value: CONSTANT.INFECTIONHISTORYLEFTPRESENTATIONDATE },
    { name: 'Symptoms Days - Left', value: CONSTANT.INFECTIONHISTORYLEFTSYMPTOMSDAYS },
    { name: 'Risk factor - Left', value: CONSTANT.INFECTIONHISTORYLEFTRISKFACTOR }
]

let scehConcepts = [
    { name: 'Blood Sugar', value: CONSTANT.EMRBLOODSUGAR }, { name: 'Referral', value: CONSTANT.EMRREFERRAL }
]

export const UserVisitSummary = ({ adultinitial, visitInProgress, visitComplete, showInfectionFragmentRight, showInfectionFragmentLeft, showMedicationFragmentRight, showMedicationFragmentLeft, showFirstVisitInfection, onChangeHandler, onClick }) => {
    const [showModal, setShowModal] = useState(false);
    const [encounterType, setEncounterType] = useState();
    const [notCompeleteConcepts, setNotCompleteConcepts] = useState([]);
    const [submit, setSubmit] = useState(false);
    const [allComment, setAllComment] = useState([]);
    const [isComment, setIsComment] = useState(false);
    const [commentValue, setCommentValue] = useState({ status: undefined, comment: undefined });
    const [obsId, setObsId] = useState();
    const [commentId, setCommentId] = useState();
    const navigate = useNavigate();
    const location = useLocation();

    const isFirstRender = useRef(true);

    useEffect(() => {
        if (isFirstRender.current) {
            if (visitComplete) {
                getComment();
            }
            isFirstRender.current = false
            return;
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submit])

    const onClickHandler = (type, value) => {
        if (['save', 'visitComplete'].includes(type)) {
            setSubmit(true);
            setTimeout(() => {
                checkVisitData();
                setEncounterType(type);
                setSubmit(false);
            }, 2000);
        } else if (type === 'comment') {
            setIsComment(prevCheck => !prevCheck);
        } else if (type === 'submitComment') {
            if (commentId) {
                editComment();
            } else {
                saveComment();
            }
        } else if (type === 'commentEdit') {
            setObsId(value.id);
            setCommentValue(value.comment.value);
            setCommentId(value.comment.id)
        } else if (type === 'commentDelete') {
            deleteComment(value.id, 'comment');
        }
    }

    const onChangeH = (type, value, extraData) => {
        if (type === 'commentValue') {
            if (extraData === 'status') {
                setCommentValue({ ...commentValue, status: value });
            } else {
                setCommentValue({ ...commentValue, comment: value });
            }
        }
    }

    const getComment = async () => {
        try {
            let { obs } = visitComplete;
            if (obs.length) {
                setAllComment(obs.map(co => {
                    return {
                        id: co.id,
                        comment: {
                            id: co.obs_object.id,
                            value: co.obs_object.value
                        },
                        name: `${co.person.first_name} ${co.person.last_name}`
                    }
                }));
                setIsComment(true);
            }
        } catch (e) {
            console.log('Get Comment Exception: ', e);
        }
    }

    const saveComment = async () => {
        try {
            let user = await getFromLocalStorage('user');
            let { id, patient_id, visit_id } = visitComplete;
            let payload = {
                encounter_id: id,
                creator_id: user.user.person_id,
                concept_id: CONSTANT.COMMENT,
                patient_id,
                visit_id,
                obs_object: {
                    value: commentValue
                }
            }
            Services.PostObs(payload).then((response) => {
                if (response.status !== 400) {
                    setAllComment(prev => [...prev,
                    {
                        comment: {
                            id: response?.obs_object?.id,
                            value: response?.obs_object?.value,
                        },
                        id: response?.id,
                        name: `${user?.user?.person?.first_name} ${user?.user?.person?.last_name}`
                    }
                    ]);
                }
                resetValues();
            })
        } catch (e) {
            console.log('save comment exception: ', e);
        }
    }

    const editComment = () => {
        try {
            let payload = {
                obs_id: obsId,
                value: commentValue
            }
            Services.PutObsObject(commentId, payload).then(response => {
                if (response?.modified_by) {
                    resetValues();
                    let index = allComment.findIndex(ac => ac.id === response.obs_id);
                    if (index !== -1) {
                        allComment[index].comment = {
                            ...allComment[index].comment,
                            value: response.value
                        };
                    }
                }
            })
        } catch (e) {
            console.log('Edit comment exception: ', e);
        }
    }

    const deleteComment = (id, path) => {
        try {
            Services.DeleteObs(id).then(response => {
                if (response.status !== 400 && response.affected) {
                    if (path === 'comment') {
                        let newData = [...allComment];
                        let index = newData.findIndex(dt => dt.id === id);
                        if (index !== -1) {
                            newData.splice(index, 1);
                            setAllComment(newData);
                        }
                    }
                }
            })
        } catch (e) {
            console.log('Delete Comment Exception: ', e);
        }

    }

    const checkVisitData = () => {
        try {
            let { id, patient_id, visit_id } = visitInProgress;
            let params = `encounter_id=${id}&patient_id=${patient_id}&visit_id=${visit_id}`;
            Services.GetObsWithParams(params).then(response => {
                if (response.rows.length) {
                    let data = [];
                    let concepts = Server.ENV.name === 'sceh' ? [...fixConcepts, ...scehConcepts] : fixConcepts;
                    if (showInfectionFragmentRight) {
                        concepts = [...concepts, ...cornealConceptsRight];
                        let procedureValue = response.rows.filter(visit => [CONSTANT.INFECTIONHISTORYRIGHTPROCEDURE].includes(visit.concept_id));
                        if (procedureValue.length) {
                            try {
                                let values = procedureValue[0]?.obs_string?.value?.split(',');
                                let therapeutic = values?.find(ft => ft.match('Therapeutic Keratoplasty Decided'));
                                if (therapeutic) {
                                    if (therapeutic.match('Yes')) {
                                        concepts = [...concepts, ...therapeuticConceptRight];
                                    }
                                }
                            } catch (e) {
                                console.log('therapeutic exception', e)
                            }
                        }
                    }
                    if (showInfectionFragmentLeft) {
                        concepts = [...concepts, ...cornealConceptsLeft];
                        let procedureValue = response.rows.filter(visit => [CONSTANT.INFECTIONHISTORYLEFTPROCEDURE].includes(visit.concept_id));
                        if (procedureValue.length) {
                            try {
                                let values = procedureValue[0]?.obs_string?.value?.split(',');
                                let therapeutic = values?.find(ft => ft.match('Therapeutic Keratoplasty Decided'));
                                if (therapeutic) {
                                    if (therapeutic.match('Yes')) {
                                        concepts = [...concepts, ...therapeuticConceptLeft];
                                    }
                                }
                            } catch (e) {
                                console.log('therapeutic exception', e)
                            }
                        }
                    }
                    if (showFirstVisitInfection) {
                        if (showInfectionFragmentRight) {
                            concepts = [...concepts, ...nestedConceptsRight];
                        }
                        if (showInfectionFragmentLeft) {
                            concepts = [...concepts, ...nestedConceptsLeft];
                        }
                    }
                    if (Server.ENV.name !== 'img') {
                        if (showInfectionFragmentRight) {
                            concepts = [...concepts, ...notImgRight];
                        }
                        if (showInfectionFragmentLeft) {
                            concepts = [...concepts, ...notImgLeft];
                        }
                    }
                    concepts.forEach(concept => {
                        if (!response.rows.find(obs => concept.value === obs.concept_id)) {
                            data.push(concept.name);
                        }
                    })
                    setNotCompleteConcepts(data);
                    if (data.length) {
                        setShowModal(true);
                    } else {
                        if (encounterType === 'save') {
                            navigate('/home', { state: location.state });
                        } else {
                            onClick('visitComplete-diagnosis');
                        }
                    }
                } else {
                    setShowModal(true);
                    setNotCompleteConcepts(['No observation for this visit']);
                }
            })
        } catch (e) {
            console.log('Audit Exception: ', e);
        }
    }

    const resetValues = () => {
        setCommentValue({ status: undefined, comment: undefined });
        setCommentId();
        setObsId();
    }


    return (
        <>
            <div style={{ fontSize: 30, textAlign: 'center', paddingBottom: 10 }}>Ophthalmologist Diagnosis</div>
            <h2 style={{ textAlign: 'center', paddingBottom: 10 }}>{new Date(adultinitial?.created_at)?.toLocaleDateString('en-US')}</h2>
            <PatientInformation data={adultinitial.patient} />
            <br />
            <OnExamination data={adultinitial} />
            <br />
            <EMR data={visitInProgress} submit={submit} showInfectionFragmentRight={showInfectionFragmentRight} showInfectionFragmentLeft={showInfectionFragmentLeft} />
            <br />
            <Diagnosis data={visitInProgress} onChange={onChangeHandler} submit={submit} />
            {showInfectionFragmentRight || showInfectionFragmentLeft ?
                <>
                    <br />
                    <div style={{ display: showInfectionFragmentRight && showInfectionFragmentLeft ? 'flex' : '', gap: '1%' }}>
                        {showInfectionFragmentRight ? <InfectionHistory name="Right" data={visitInProgress} submit={submit} onChange={onChangeHandler} showFirstVisitInfection={showFirstVisitInfection} showInfectionFragmentRight={showInfectionFragmentRight} /> : null}
                        {showInfectionFragmentLeft ? <InfectionHistory name="Left" data={visitInProgress} submit={submit} onChange={onChangeHandler} showFirstVisitInfection={showFirstVisitInfection} showInfectionFragmentLeft={showInfectionFragmentLeft} /> : null}
                    </div>

                </>
                : null}

            {/* <br /> */}
            {/* <SlitLampFinding data={visitInProgress} /> */}

            {/* {showMedicationFragmentRight || showMedicationFragmentLeft ?
                <>
                    <br />
                    <div style={{ display: showMedicationFragmentRight && showMedicationFragmentLeft ? 'flex' : '', gap: '1%' }}>
                        {showMedicationFragmentRight ? <MedicalHistory name="Right" data={visitInProgress} /> : null}
                        {showMedicationFragmentLeft ? <MedicalHistory name="Left" data={visitInProgress} /> : null}
                    </div>
                </>
                : null} */}
            <br />
            <FollowUp data={visitInProgress} />
            <br />
            <div style={{ display: 'flex', gap: '1%' }}>
                <MedicalHistory name="Right" data={visitInProgress} />
                <MedicalHistory name="Left" data={visitInProgress} />
            </div>
            <br />
            {Server.ENV.name === 'sceh' ? <SlitLampFindingPhoto data={visitInProgress} /> : null}
            <br />
            <SubmitWrapper>
                <Button type="primary" onClick={() => onClickHandler('save')}>Save and exit</Button> &nbsp;
                <Button type="primary" onClick={() => onClickHandler('visitComplete')}>Complete Visit</Button>
            </SubmitWrapper>
            {visitComplete ?
                <>
                    <ChatWrapper>
                        <WechatOutlined style={{ color: '#008000', fontSize: '40px' }} onClick={() => onClickHandler('comment')} />
                    </ChatWrapper>
                    {isComment ? <CommentBot allComment={allComment} commentValue={commentValue} onChange={onChangeH} onClick={onClickHandler} /> : null}
                </>
                : null}
            {showModal ?
                <ModalComponent
                    width='450px'
                    open={true}
                    title={encounterType === 'save' ? 'Are you sure you want to exit?' : 'Are you sure you want to complete this visit?'}
                    saveData={() => encounterType === 'save' ? navigate('/home', { state: location.state }) : onClick('visitComplete-diagnosis')}
                    onCancel={() => { setShowModal(false) }}>
                    <h4>The following fields are not complete: </h4>
                    <ol>{notCompeleteConcepts.map(name => <li>{name}</li>)}</ol>
                </ModalComponent> : null}
        </>
    )
}