
import { useState, useRef, useEffect } from "react";
import { Select, Radio, Space, AutoComplete, notification, Input } from 'antd';
import { Services } from '../../__services';
import { DeleteFilled } from '@ant-design/icons';
import { calculateAge, getCookie, getFromLocalStorage, DecryptionString } from "../../Utils";
import { DragAndDrop, ModalComponent } from "../../Component/Common";
import { UploadImgeWrapper, PhotoWrapper, PhotoSection, PhotoCardWrapper, PhotoContent, PreviewCard, IframeWrapper } from './style';

const { Option } = Select;

let timeout;

const imageTypeList = [
    { value: 'Slit lamp diffuse illumination' }, { value: 'Slit lamp blue light' }, { value: 'Pentacam' },
    { value: 'ASOCT' }, { value: 'Paper form and consent form' }, { value: 'Microbiology results' }
]



export const UploadImage = ({ onClick }) => {
    const [api, contextHolder] = notification.useNotification();
    const [patient, setPatient] = useState([]);
    const [selectedPatient, setSelectedPatient] = useState();
    const [selectedVisit, setSelectedVisit] = useState();
    const [imageUpload, setImageUpload] = useState(true);
    const [imageList, setImageList] = useState([]);
    const [imageType, setImageType] = useState();
    const [imageSide, setImageSide] = useState();
    const [modalLoading, setModalLoading] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [password, setPassword] = useState();
    const [selectedPhoto, setSelectedPhoto] = useState();
    const [iframeSrc, setIframeSrc] = useState();

    const isFirstRender = useRef(true);

    useEffect(() => {
        if (isFirstRender.current) {
            getPatients();
            isFirstRender.current = false
            return;
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const IframeWindow = ({ src }) => {
        return (
            <IframeWrapper>
                <div><span onClick={() => { onClickHandler('preview', null) }}>X</span></div>
                <iframe src={src}></iframe>
            </IframeWrapper>
        )
    }

    const openNotificationWithIcon = (type, title, description) => {
        api[type]({
            message: title,
            description,
        });
    };

    const onClickHandler = (type, value) => {
        if (type === 'preview') {
            setIframeSrc(value);
        } else if (type === 'deletePhoto') {
            setSelectedPhoto(value);
            setShowDeleteModal(true);
        }
    }

    const onChangeHandler = (type, value, additionalInfo) => {
        // if (type === 'search') {
        //     clearTimeout(timeout);
        //     timeout = setTimeout(() => { searchPatient(value) }, 3000);
        // } else 
        if (type === 'selectedPatient') {
            setSelectedPatient(value);
            setSelectedVisit();
        } else if (type === 'imageType') {
            setImageType(value);
            imageList[imageList.length - 1].imageType = value;
            if (imageList[imageList.length - 1].imageSide) {
                setImageUpload(true);
                resetField();
            }
        } else if (type === 'imageSide') {
            setImageSide(value);
            imageList[imageList.length - 1].imageSide = value;
            if (imageList[imageList.length - 1].imageType) {
                setImageUpload(true);
                resetField();
            }
        } else if (type === 'image') {
            if (additionalInfo !== 'removed') {
                setImageUpload(false);
            }
            setImageList(value);
        }
    }

    const getPatients = (value) => {
        try {
            let params = ``;
            // params += `patient_identifier=${value}`
            params += `&customQuery=person,images,visits`;
            Services.GetPatientWithParams(params).then(response => {
                setPatient(response.rows);
            })
        } catch (e) {
            console.log('Get Patient Exception: ', e);
        }
    }

    const saveImage = () => {
        setModalLoading(true);
        let userInfo = getFromLocalStorage('user');
        let patientInfo = patient.find(pt => pt.patient_identifier === selectedPatient);
        let allData = [];
        if (patientInfo) {
            let payload = {
                created_by: userInfo.user.id,
                patient_id: patientInfo.id,
                visit_id: selectedVisit,
            }
            imageList.forEach(img => {
                let data = {
                    ...payload,
                    type: `${img.imageSide} - ${img.imageType}`
                }
                allData.push(data);
            })
            Services.PostImage(allData).then(imageResponse => {
                if (imageResponse.length) {
                    imageResponse.forEach((rp, index) => {
                        var formData = new FormData();
                        formData.append('file', imageList[index].originFileObj);
                        formData.append('uuid', rp.id);
                        Services.PostImageAZURE(formData).then(azureResponse => {
                            if (azureResponse.length) {
                                if (index + 1 === imageResponse.length) {
                                    onClick('showImageUpload', false);
                                }
                            }
                        })
                    })
                }
            })
        }
    }

    const deleteImage = () => {
        try {
            let pass = DecryptionString(getCookie('userP'));
            if (selectedPhoto && password === pass) {
                Services.DeleteImage(selectedPhoto).then(response => {
                    if (response) {
                        openNotificationWithIcon('success', 'Image Deleted');
                        setShowDeleteModal(false);
                        let patientIndex = patient.findIndex(pt => pt?.patient_identifier === selectedPatient) //?.images.find(img => img.id === selectedPatient);
                        if (patientIndex !== -1) {
                            let imageIndex = patient[patientIndex].images.findIndex(img => img.id === selectedPhoto);
                            if (imageIndex !== -1) {
                                patient[patientIndex].images.splice(imageIndex, 1);
                                let pat = [...patient];
                                setPatient(pat);
                            }
                        }
                    }
                })
            } else {
                openNotificationWithIcon('error', 'Incorrect password. Please try again.');
            }
        } catch (e) {
            console.log('Image Delete Exception: ', e);
        }
    }

    const resetField = () => {
        setImageSide();
        setImageType();
    }

    return (
        <>
            <ModalComponent
                width='900px'
                open={true}
                title='Upload Image'
                saveData={saveImage}
                loading={modalLoading}
                onCancel={() => onClick('showImageUpload', false)}>
                <Select
                    showSearch
                    value={selectedPatient}
                    placeholder='Patient study ID'
                    style={{ width: 200 }}
                    defaultActiveFirstOption={false}
                    suffixIcon={null}
                    onChange={e => onChangeHandler('selectedPatient', e)}
                    onSearch={(e) => onChangeHandler('search', e)}
                    notFoundContent={null}
                    filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        || option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {patient.map((d, index) => <Option key={index} value={d.patient_identifier}>{`${d?.patient_identifier} (${calculateAge(d?.person?.birthdate)} ${d?.person?.gender == 'female' ? 'F' : 'M'}) `}</Option>)}
                </Select>

                {selectedPatient ?
                    <AutoComplete
                        placeholder="Select Visit"
                        options={patient.find(pt => pt?.patient_identifier === selectedPatient)?.visits.map(visit => { return { value: visit?.id, label: new Date(visit?.created_at).toLocaleDateString('en-US') } })}
                        value={selectedVisit} onChange={e => setSelectedVisit(e)}
                        style={{ width: 200, marginLeft: 10 }}
                        filterOption={(inputValue, option) =>
                            option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}>
                    </AutoComplete> : null}
                {selectedPatient && selectedVisit ?
                    <>
                        <br />
                        {patient?.map((pt, index) => pt?.patient_identifier === selectedPatient ?
                            <PhotoWrapper key={index}>
                                <PhotoSection>
                                    {pt?.images.map((img, rightIndex) => (img?.type === 'right' || img?.type.match('right')) && img.visit_id === selectedVisit ?
                                        <PhotoCardWrapper key={rightIndex}>
                                            {img?.image_name?.split('.').pop() === 'pdf' ? <PreviewCard onClick={() => onClickHandler('preview', img.image_path)}>Preview</PreviewCard> :
                                                <img src={img?.image_path} height={'100px'} />}
                                            <PhotoContent>
                                                {/* <div>{img?.imageType}</div> */}
                                                <DeleteFilled onClick={() => onClickHandler('deletePhoto', img.id)} style={{ color: 'red', marginTop: '10px' }} />
                                                <div style={{ textAlign: 'end' }}>{img?.type}</div>
                                            </PhotoContent>
                                        </PhotoCardWrapper> : null)}
                                </PhotoSection>
                                <PhotoSection>
                                    {pt?.images.map((img, leftIndex) => (img?.type === 'left' || img?.type.match('left')) && img.visit_id === selectedVisit ?
                                        <PhotoCardWrapper key={leftIndex}>
                                            {img?.image_name?.split('.').pop() === 'pdf' ? <PreviewCard onClick={() => onClickHandler('preview', img.image_path)}>Preview</PreviewCard> :
                                                <img src={img?.image_path} height={'100px'} />}
                                            <PhotoContent>
                                                {/* <div>{img?.imageType}</div> */}
                                                <DeleteFilled onClick={() => onClickHandler('deletePhoto', img.id)} style={{ color: 'red', marginTop: '10px' }} />
                                                <div style={{ textAlign: 'end' }}>{img?.type}</div>
                                            </PhotoContent>
                                        </PhotoCardWrapper> : null
                                    )}
                                </PhotoSection>
                            </PhotoWrapper>
                            : null)}
                        <br />
                        <UploadImgeWrapper>
                            <div>
                                <DragAndDrop onChange={onChangeHandler} disabled={imageUpload} />
                            </div>
                            <div>
                                <div>
                                    <h4>Image Type:</h4>
                                    <Radio.Group disabled={imageUpload} value={imageType} onChange={(e) => onChangeHandler('imageType', e.target.value)}>
                                        <Space direction="vertical">
                                            {imageTypeList.map((type, index) => <Radio key={index} value={type.value}>{type.value}</Radio>)}
                                        </Space>
                                    </Radio.Group>
                                </div>
                                <div>
                                    <h4>Which Eye:</h4>
                                    <Radio.Group disabled={imageUpload} value={imageSide} onChange={(e) => onChangeHandler('imageSide', e.target.value)}>
                                        <Space direction="vertical">
                                            {[{ value: 'right', label: 'Right' }, { value: 'left', label: 'Left' }].map((type, index) => <Radio key={index} value={type.value}>{type.label}</Radio>)}
                                        </Space>
                                    </Radio.Group>
                                </div>
                            </div>
                        </UploadImgeWrapper>
                    </> : null}
            </ModalComponent>
            {iframeSrc ? <IframeWindow src={iframeSrc} /> : null}
            {showDeleteModal ?
                <ModalComponent
                    width='400px'
                    open={true}
                    title='Are you Sure?'
                    saveData={deleteImage}
                    onCancel={() => { setSelectedPatient(); setShowDeleteModal(false) }}>
                    <div>Are you sure you want to delete? Enter your password to proceed</div>
                    <Input placeholder="Enter your password" onChange={(e) => setPassword(e.target.value)} />
                </ModalComponent> : null}
            {contextHolder}
        </>
    )
}