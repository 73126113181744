import { useState, useRef, useEffect } from 'react';
import { Card, Button, notification } from 'antd';
import { ImageUpload } from '../../../../Component/Common';
import { PhotoWrapper, PhotoSection } from './style';
import { Services } from '../../../../__services';
import { getFromLocalStorage } from '../../../../Utils';


export const SlitLampFindingPhoto = ({ data }) => {
    const [api, contextHolder] = notification.useNotification();
    const [fileList, setFileList] = useState([]);
    const [reset, setReset] = useState(false);
    const isFirstRender = useRef(true);

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false
            return;
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const openNotificationWithIcon = (type, title, description) => {
        api[type]({
            message: title,
            description,
        });
    };

    const onChangeHandler = (type, value) => {
        if (type === 'file') {
            let data = [...fileList, ...value];
            setFileList(data);
        } else if (type === 'deleteFile') {
            let data = [...fileList];
            let deleteindex = data.findIndex(fl => fl.type === value);
            if (deleteindex !== -1) {
                data.splice(deleteindex, 1);
                setFileList(data);
            }
        }
    }

    const submitPhoto = () => {
        let userInfo = getFromLocalStorage('user');
        let allData = [];
        if (fileList.length) {
            let payload = {
                created_by: userInfo.user.id,
                patient_id: data?.visit.patient_id,
                visit_id: data?.visit?.id,
            }
            fileList.forEach(img => {
                let data = {
                    ...payload,
                    type: `${img.type}`
                }
                allData.push(data);
            })
            Services.PostImage(allData).then(imageResponse => {
                if (imageResponse.length) {
                    imageResponse.forEach((rp, index) => {
                        var formData = new FormData();
                        formData.append('file', fileList[index].originFileObj);
                        formData.append('uuid', rp.id);
                        Services.PostImageAZURE(formData).then(azureResponse => {
                            if (azureResponse.length) {
                                if (index + 1 === imageResponse.length) {
                                    openNotificationWithIcon('success', 'Image Uploaded Successfully');
                                    setReset(true);
                                }
                            }
                        })
                    })
                }
            })
        }
    }

    return (
        <div>
            <Card title={(<>Slit Lamp Photos</>)}>
                <PhotoWrapper>
                    <PhotoSection>
                        <div>
                            <h5>Right eye - Diffuse Light</h5>
                            <ImageUpload name='right - slit lamp diffuse illumination' onChange={onChangeHandler} reset={reset} />
                        </div>
                        <div>
                            <h5>Right eye - Blue Light</h5>
                            <ImageUpload name='right - slit lamp blue light' onChange={onChangeHandler} reset={reset} />
                        </div>
                    </PhotoSection>
                    <PhotoSection>
                        <div>
                            <h5>Left eye - Diffuse Light</h5>
                            <ImageUpload name='left - slit lamp diffuse illumination' onChange={onChangeHandler} reset={reset} />
                        </div>
                        <div>
                            <h5>Left eye - Blue Light</h5>
                            <ImageUpload name='left - slit lamp blue light' onChange={onChangeHandler} reset={reset} />
                        </div>
                    </PhotoSection>
                </PhotoWrapper>
                <br />
                <div style={{ textAlign: 'center' }}>
                    <Button type='primary' onClick={submitPhoto}>Submit Photos</Button>
                </div>
            </Card>
            {contextHolder}
        </div>
    )
}