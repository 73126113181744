import styled from "styled-components";

export const BotWrapper = styled.div`
    height: 60%;
    width: 21%;
    box-shadow: 2px 5px 6px 4px rgba(0, 0, 0, 0.2);
    background: white;
    position: fixed;
    bottom: 70px;
    right: 77px;
    z-index: 999;
    font-size: 11px;
`

export const BotHeader = styled.div`
    height: 35px;
    width: 100%;
    background: blue;
    padding: 10px;
`;

export const BotBody = styled.div`
    height: calc(100% - 85px);
    padding: 5px;
    overflow: auto;
`;

export const BotInput = styled.div`
    padding: 10px;
`;

export const CommentWrapper = styled.div`
    display: flex;
    gap: 4%;
    align-items: center;
`;

export const CommentList = styled.div`
    padding: 5px;
    background: ghostwhite;
    margin: 5px 0px;
    width: 88%;
`;