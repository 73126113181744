import { CONSTANT } from '../../../Utils';

export const processVisitData = (datas = [], type) => {
    let newDatas = {
        awaitingConsult: [],
        visitInProgress: [],
        visitComplete: [],
        visitMicrobiology: [],
        visitMicrobiologyResult: [],
        visitMicrobiologyComplete: []
    }
    return new Promise((resolve) => {
        if (datas.length) {
            datas.forEach((data, index) => {
                let { id, encounters, location, patient } = data;
                if (encounters.length) {
                    let typeOfVisit = 'awaitingConsult', encounter;
                    if (type === 'user') {
                        let VISITCOMPLETE = encounters.find(enc => enc.encounter_type_id === CONSTANT.ENCOUNTERVISITCOMPLETE);
                        if (VISITCOMPLETE) {
                            encounter = VISITCOMPLETE
                            typeOfVisit = 'visitComplete';
                        }
                        else {
                            let VISITINPROGRESS = encounters.find(enc => enc.encounter_type_id === CONSTANT.ENCOUNTERVISITINPROGRESS);
                            if (VISITINPROGRESS) {
                                encounter = VISITINPROGRESS
                                typeOfVisit = 'visitInProgress';
                            } else {
                                encounter = encounters[0];
                            }
                        }
                    } else {
                        let VISITMICROBIOLOGYCOMPLETE = encounters.find(enc => enc.encounter_type_id === CONSTANT.ENCOUNTERMICROBIOLOGYCOMPLETE);
                        let VISITMICROBIOLOGYRESULT = encounters.find(enc => enc.encounter_type_id === CONSTANT.ENCOUNTERMICROBIOLOGYRESULTS);
                        let VISITMICROBIOLOGY = encounters.find(enc => enc.encounter_type_id === CONSTANT.ENCOUNTERMICROBIOLOGY);
                        if (VISITMICROBIOLOGYCOMPLETE) {
                            encounter = VISITMICROBIOLOGYCOMPLETE
                            typeOfVisit = 'visitMicrobiologyComplete';
                        } else if (VISITMICROBIOLOGYRESULT) {
                            encounter = VISITMICROBIOLOGYRESULT
                            typeOfVisit = 'visitMicrobiologyResult';
                        } else if (VISITMICROBIOLOGY) {
                            encounter = VISITMICROBIOLOGY
                            typeOfVisit = 'visitMicrobiology';
                        }

                    }
                    if (encounter) {
                        newDatas[typeOfVisit].push(
                            {
                                key: id,
                                patient_id: patient.id,
                                patient_identifier: patient.patient_identifier,
                                abha_no: patient.abha_no,
                                name: `${patient.person.first_name} ${patient.person.middle_name || ''} ${patient.person.last_name}`,
                                gender: patient.person.gender,
                                dob: patient.person.birthdate,
                                location: location.name,
                                provider: `${encounter.creator.first_name} ${encounter.creator.middle_name || ''} ${encounter.creator.last_name}`
                            }
                        )
                    }
                }
                if (index + 1 === datas.length) {
                    resolve(newDatas)
                }
            })
        } else {
            resolve(newDatas)
        }
    })
}

const processLens = (datas = [], location = []) => {
    // return new Promise(resolve => {
    let leftEye = datas.filter(data => data.concept_id === CONSTANT.DIAGNOSISLEFTEYELENS);
    let rightEye = datas.filter(data => data.concept_id === CONSTANT.DIAGNOSISRIGHTEYELENS);
    let totalLensDiagnosis = [...leftEye, ...rightEye];
    let lensDiagnosis = getLensByDiagnosis(totalLensDiagnosis);
    let byLocation = getByLocationDiagnosis(lensDiagnosis, location);
    let byDay = {
        'Mature cataract': getByTime(lensDiagnosis.matureCataract),
        'Immature cataract': getByTime(lensDiagnosis.immatureCataract),
        'Clear lens': getByTime(lensDiagnosis.clearLens),
        'PCIOL': getByTime(lensDiagnosis.pciol),
        'Aphakia': getByTime(lensDiagnosis.aphakia),
    }
    return {
        totalLens: totalLensDiagnosis.length,
        lensDiagnosis,
        byDay,
        byLocation
    }
    // })
}

const processAdditional = (datas = [], location = []) => {
    let leftEye = datas.filter(data => data.concept_id === CONSTANT.DIAGNOSISLEFTEYEADDITIONALPATHALOGY);
    let rightEye = datas.filter(data => data.concept_id === CONSTANT.DIAGNOSISRIGHTEYEADDITIONALPATHALOGY);
    let totalAdditionalDiagnosis = [...leftEye, ...rightEye];
    let additionalDiagnosis = getAdditionalByDiagnosis(totalAdditionalDiagnosis);
    let byLocation = getByLocationPathology(additionalDiagnosis, location)
    let byDay = {
        'Active corneal infection': getByTime(additionalDiagnosis.activeCorneal),
        'Inactive corneal opacity': getByTime(additionalDiagnosis.inactiveCorneal),
        'Epithelial Defect': getByTime(additionalDiagnosis.epithelial),
        'Corneal Abrasion': getByTime(additionalDiagnosis.corneal),
        'Pterygium': getByTime(additionalDiagnosis.pterygium),
    }
    return {
        totalDiagnosis: totalAdditionalDiagnosis.length,
        additionalDiagnosis,
        byDay,
        byLocation
    }
}


export const processObsData = (datas = [], location, filterLocation = []) => {
    return new Promise((resolve) => {
        if (datas.length) {
            if (filterLocation.length) {
                datas = datas.filter(data => filterLocation.includes(data.person.location_id))
            }
            let lensData = processLens(datas, location);
            let addData = processAdditional(datas, location);
            resolve({
                lens: lensData,
                add: addData
            })
        } else {
            resolve(datas)
        }
    })
}

const getLensByDiagnosis = (datas = []) => {
    return {
        matureCataract: datas.filter(data => data.obs_string.value === 'Mature cataract'),
        immatureCataract: datas.filter(data => data.obs_string.value === 'Immature cataract'),
        clearLens: datas.filter(data => data.obs_string.value === 'Clear Crystalline lens'),
        pciol: datas.filter(data => data.obs_string.value === 'PCIOL'),
        aphakia: datas.filter(data => data.obs_string.value === 'Aphakia'),
    }
}

const getAdditionalByDiagnosis = (datas = []) => {
    return {
        activeCorneal: datas.filter(data => data.obs_string.value === 'Active corneal infection'),
        inactiveCorneal: datas.filter(data => data.obs_string.value === 'Inactive corneal opacity'),
        epithelial: datas.filter(data => data.obs_string.value === 'Epithelial Defect'),
        corneal: datas.filter(data => data.obs_string.value === 'Corneal Abrasion'),
        pterygium: datas.filter(data => data.obs_string.value === 'Pterygium'),
    }
}

const getByTime = (datas = []) => {
    return [
        datas.filter(data => new Date(data.created_at).getDay() === 0).length,
        datas.filter(data => new Date(data.created_at).getDay() === 1).length,
        datas.filter(data => new Date(data.created_at).getDay() === 2).length,
        datas.filter(data => new Date(data.created_at).getDay() === 3).length,
        datas.filter(data => new Date(data.created_at).getDay() === 4).length,
        datas.filter(data => new Date(data.created_at).getDay() === 5).length,
        datas.filter(data => new Date(data.created_at).getDay() === 6).length,
    ]
}

const getByLocationDiagnosis = (datas = [], location) => {
    let data = {};
    location.forEach(location => {
        data = {
            ...data,
            [location.name]: [
                datas.matureCataract.filter(data => data.person.location_id === location.id).length,
                datas.immatureCataract.filter(data => data.person.location_id === location.id).length,
                datas.clearLens.filter(data => data.person.location_id === location.id).length,
                datas.pciol.filter(data => data.person.location_id === location.id).length,
                datas.aphakia.filter(data => data.person.location_id === location.id).length,
            ]
        }
    })
    return data;
}

const getByLocationPathology = (datas = [], location) => {
    let data = {};
    location.forEach(location => {
        data = {
            ...data,
            [location.name]: [
                datas.activeCorneal.filter(data => data.person.location_id === location.id).length,
                datas.inactiveCorneal.filter(data => data.person.location_id === location.id).length,
                datas.epithelial.filter(data => data.person.location_id === location.id).length,
                datas.corneal.filter(data => data.person.location_id === location.id).length,
                datas.pterygium.filter(data => data.person.location_id === location.id).length,
            ]
        }
    })
    return data;
}