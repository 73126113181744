import { Card } from 'antd';
import { BadgeWrapper, CardData, Badge } from './style';

export const FilterComponent = ({ initalVisit, followUpVisit }) => {

    return (
        <>
            <BadgeWrapper>
                <Card size="small" style={{ width: '80%' }}>
                    <CardData>
                        <div style={{ fontSize: 20 }}>Patients Recruited</div>
                        <Badge color='#FF7440'>{initalVisit.length}</Badge>
                    </CardData>
                </Card>
                <Card size="small" style={{ width: '80%' }}>
                    <CardData>
                        <div style={{ fontSize: 20 }}>Follow up visits</div>
                        <Badge color='#003BFF'>{followUpVisit.length}</Badge>
                    </CardData>
                </Card>
            </BadgeWrapper>
        </>
    )
}