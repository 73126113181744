import styled from "styled-components";

export const LensWrapper = styled.div`
    display: flex;
    gap: 10%;
`;

export const LensSection = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const PathalogyWrapper = styled.div`
    display: flex;
    gap: 10%;
`;

export const PathalogySection = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const CornealWrapper = styled.div`
    
`;