import * as AuthHelper from './APIHelper';

export const GetRole = () => {
    return AuthHelper.getResponse('/role');
}

export const GetRoleWithParams = (params) => {
    return AuthHelper.getResponse(`/role?${params}`);
}

export const PostRole = (payload) => {
    return AuthHelper.postResponse('/role', payload);
}

export const PutRole = (id, payload) => {
    return AuthHelper.putResponse(`/role/${id}`, payload);
}

export const DeleteRole = (id) => {
    return AuthHelper.deleteResponse(`/role/${id}`);
}