import { PatientInformation } from "./PatientInformation";
import { Microbiologic } from "./Microbiologic";
import { Button } from 'antd';

export const CoordinatorVisitSummary = ({visitInProgress, visitMicrobiologyResult, onClick, submitCoordinator}) => {
    return (
        <div>
            <div style={{fontSize: 30, textAlign: 'center', paddingBottom: 10}}>Microbiology Results</div>
            <PatientInformation data={visitInProgress} visitInProgress={visitInProgress}/>
            <br/>
            <Microbiologic data={visitMicrobiologyResult} submitCoordinator={submitCoordinator} clickHandler={onClick} />
            <br/>
            <div style={{display: "flex", justifyContent: "center", gap: 10}}>
                <Button type="text" onClick={() => onClick('visitNote')}>Save Incomplete</Button>
                <Button type="primary" onClick={() => onClick('visitCompleteCoordinator')}>Finish and Submit</Button>
            </div>
        </div>
    )
}