import { useEffect, useRef, useState } from 'react';
import { Card } from "antd";
import { getTotalVisit } from '../../data';
import { SpinnerComponent } from "../../../../../Component/Common";
import { BadgeWrapper } from './style';

export const PatientVolume = ({ filterData }) => {
    const [filter, setFilter] = useState();
    const [visitData, setVisitData] = useState();
    const [loading, setLoading] = useState(true);
    const isFirstRender = useRef(true);

    useEffect(() => {
        if (isFirstRender.current) {
            getAllData();
            isFirstRender.current = false
            return;
        }
        try {
            if ((filterData && !filter) || (filterData && JSON.stringify(Object.values(filterData)) !== JSON.stringify(Object.values(filter)))) {
                setFilter(filterData);
                setLoading(true);
                getAllData(Object.keys(filterData)[0], Object.values(filterData)[0]);
            }
        } catch (e) {
            console.log('Filter Exception :', e)
        }
        // eslint-disable-next-line
    }, [filterData])

    const getAllData = async (type, value) => {
        let lastMonth = new Date(new Date().setDate(new Date().getDate() - 30)).toISOString().split('T')[0];
        let todayDate = new Date().toISOString().split('T')[0];
        let dates = [lastMonth, todayDate];
        let param = `&created_at=${dates}`;
        if (type) {
            if (type === 'date') {
                if (!value.length) {
                    param = `&created_at=${dates}`
                } else {
                    param = `&created_at=${value}`;
                }
            } else if (type === 'location') {
                param += `&location_id=${value.join()}`
            } else if (type === 'healthWorker' || type === 'doctor') {
                param = `&creator_id${value}`;
            }
        }
        setVisitData(await getTotalVisit(param));
        setLoading(false);
    }

    return (
        <Card title={(<>Patient Volume and Referral</>)}>
            {loading ?
                <SpinnerComponent /> :
                visitData ?
                    <BadgeWrapper>
                        <Card style={{ width: '24%' }}>
                            <div style={{ fontSize: 20 }}>Total Visits</div>
                            <div style={{ fontSize: 40, fontWeight: 'bold' }}>{visitData.totalVisit}</div>
                            <div>patient</div>
                        </Card>
                        <Card style={{ width: '24%' }}>
                            <div style={{ fontSize: 20 }}>Patient Referred</div>
                            <div style={{ fontSize: 40, fontWeight: 'bold' }}>{visitData.totalVisit}</div>
                            <div>patient</div>
                        </Card>
                        <Card style={{ width: '24%' }}>
                            <div style={{ fontSize: 20 }}>Urgent Referral</div>
                            <div style={{ fontSize: 40, fontWeight: 'bold' }}>{visitData.totalVisit}</div>
                            <div>patient</div>
                        </Card>
                        <Card style={{ width: '24%' }}>
                            <div style={{ fontSize: 20 }}>Awaiting Doctor Review</div>
                            <div style={{ fontSize: 40, fontWeight: 'bold' }}>{visitData.awaitingConsult.length}</div>
                            <div>patient</div>
                        </Card>
                    </BadgeWrapper>
                    : null
            }
        </Card>
    )
}