import { useState, useRef, useEffect } from "react";
import { Button, notification } from "antd";
import { Services } from "../../../__services";
import { ModalComponent, TableSort } from "../../../Component/Common";
import { AddEditUser } from "./AddEdit";
import { CONSTANT } from "../../../Utils";

const columns = [
    { title: 'Name', dataIndex: 'name' },
    { title: 'Username', dataIndex: 'username' },
    { title: 'Location', dataIndex: 'location' },
    { title: 'Role', dataIndex: 'role' },
]

export const User = () => {
    const [allUsers, setAllUsers] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [valueEdit, setValueEdit] = useState(undefined);
    const [loading, setLoading] = useState(false);
    const [api, contextHolder] = notification.useNotification();
    const [firstName, setFirstName] = useState();
    const [middleName, setMiddleName] = useState();
    const [lastName, setLastName] = useState();
    const [email, setEmail] = useState();
    const [username, setUserName] = useState();
    const [password, setPassword] = useState();
    const [confirmPassword, setConfirmPassword] = useState();
    const [birthdate, setBirthdate] = useState();
    const [gender, setGender] = useState();
    const [location, setLocation] = useState();
    const [role, setRole] = useState();
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        total: 10
    })
    const [tableLoading, setTableLoading] = useState(false);
    const isFirstRender = useRef(true);


    useEffect(() => {
        if (isFirstRender.current) {
            getAllUsers();
            isFirstRender.current = false
            return;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onClickHandler = (type, value) => {
        if (type === 'addUser') {
            setModalTitle('Add User');
            setShowModal(true);
        } else if (type === 'edit') {
            setModalTitle('Edit User');
            setShowModal(true);
            setFirstName(value.first_name);
            setMiddleName(value.middle_name);
            setLastName(value.last_name);
            setEmail(value.email);
            setUserName(value.username);
            setGender(value.gender);
            setLocation(value.location_id);
            setBirthdate(value.birthdate);
            setRole(value.role);
            setValueEdit(value);
        } else if (type === 'delete') {
            setModalTitle('Are you Sure');
            setShowModal(true);
            setValueEdit(value);
        }
    }

    const onChangeHandler = (type, value) => {
        if (type === 'first_name') {
            setFirstName(value);
        } else if (type === 'middle_name') {
            setMiddleName(value);
        } else if (type === 'last_name') {
            setLastName(value);
        } else if (type === 'email') {
            setEmail(value);
        } else if (type === 'username') {
            setUserName(value);
        } else if (type === 'password') {
            setPassword(value);
        } else if (type === 'confirm_password') {
            setConfirmPassword(value);
        } else if (type === 'birthdate') {
            setBirthdate(value);
        } else if (type === 'gender') {
            setGender(value);
        } else if (type === 'location') {
            setLocation(value);
        } else if (type === 'role') {
            setRole(value);
        } else if (type === 'table') {
            getAllUsers(value);
        }
    }

    const getAllUsers = async (value = pagination) => {
        try {
            setTableLoading(true);
            let params = `take=10&skip=${(value.current - 1) * 10}&customQuery=person(location),person_type`;
            let users = await Services.GetUserWithParams(params);
            let newUser = [];
            users.rows.forEach(user => {
                newUser.push({
                    name: `${user.person.first_name} ${user.person.middle_name} ${user.person.last_name}`,
                    first_name: user.person.first_name,
                    middle_name: user.person.middle_name,
                    last_name: user.person.last_name,
                    email: user.email,
                    username: user.username,
                    birthdate: new Date(user.person.birthdate).toISOString().slice(0, 10),
                    gender: user.person.gender,
                    location: user.person.location.name,
                    location_id: user.person.location.id,
                    role: [...user.roles.map(role => role.name).join(', ')],
                    person_type: user.person_type.id,
                    id: user.id,
                    person_id: user.person_id
                })
            })
            setAllUsers(newUser);
            setPagination({
                ...pagination,
                ...value,
                total: users.total
            });
            setTableLoading(false);
        } catch (err) {
            console.log('Fetch User Exceptions :', err);
            setTableLoading(false);
        }
    }

    const saveUser = async () => {
        try {
            if (valueEdit) {
                if (modalTitle === 'Edit User') {
                    editUser();
                } else {
                    deleteUser();
                }
                return;
            }
            if (password !== confirmPassword) {
                openNotificationWithIcon('error', 'Password and Confirm Password doesn\'t match');
                return;
            }
            if (firstName && middleName && lastName && email && username && gender && location) {
                setLoading(true);
                let payload = {
                    first_name: firstName,
                    middle_name: middleName,
                    last_name: lastName,
                    user: {
                        email: email,
                        username: username,
                        password: password,
                        person_type_id: CONSTANT.USER,
                        roles: [...role.map(role => role === CONSTANT.DOCTORROLE ? { id: CONSTANT.DOCTORROLE, name: 'Doctor' } : role === CONSTANT.NURSEROLE ? { id: CONSTANT.NURSEROLE, name: 'Nurse'} : { id: CONSTANT.COORDINATORROLE, name: 'Coordinator' })]
                    },
                    birthdate: birthdate,
                    gender: gender,
                    location_id: location,
                    person_type_id: CONSTANT.USER
                }
                let saveUser = await Services.PostPerson(payload);
                if (saveUser.id) {
                    setTimeout(() => {
                        setShowModal(false);
                        setLoading(false);
                        resetFields();
                    }, 2000)
                    openNotificationWithIcon('success', 'User created Successfully');
                    getAllUsers();
                } else {
                    setLoading(false);
                    openNotificationWithIcon('error', 'Data not saved');
                }
            } else {
                setTimeout(() => {
                    openNotificationWithIcon('error', 'Enter all details');
                    setLoading(false);
                }, 1000)
            }
        } catch (err) {
            console.log('Save User Exception :', err);
            openNotificationWithIcon('error', 'Data not saved');
        }
    }

    const editUser = async () => {
        try {
            setLoading(true);
            let payload = {
                id: valueEdit.person_id,
                first_name: firstName,
                middle_name: middleName,
                last_name: lastName,
                user: {
                    id: valueEdit.id,
                    person_id: valueEdit.person_id,
                    email: email,
                    username: username,
                    password: password,
                    person_type_id: CONSTANT.USER,
                    roles: [...role.map(role => role === CONSTANT.DOCTORROLE ? { id: CONSTANT.DOCTORROLE, name: 'Doctor' } : role === CONSTANT.NURSEROLE ? { id: CONSTANT.NURSEROLE, name: 'Nurse'} : { id: CONSTANT.COORDINATORROLE, name: 'Coordinator' })]
                },
                birthdate: birthdate,
                gender: gender,
                location_id: location,
                person_type_id: CONSTANT.USER
            }
            let editUser = await Services.PostPerson(payload);
            if (editUser.id) {
                setTimeout(() => {
                    setShowModal(false);
                    setLoading(false);
                    setValueEdit(undefined);
                    resetFields();
                }, 2000)
                openNotificationWithIcon('success', 'Saved Successfully');
                getAllUsers();
            } else {
                openNotificationWithIcon('error', 'Data not saved');
            }
        } catch (err) {
            console.log('Save User Exception :', err);
            openNotificationWithIcon('error', 'Data not saved');
        }
    }

    const deleteUser = async () => {
        try {
            setLoading(true);
            let deleteUser = await Services.DeleteUser(valueEdit.id);
            if (deleteUser) {
                openNotificationWithIcon('success', 'Deleted Successfully');
                getAllUsers();
                setTimeout(() => {
                    setShowModal(false);
                    setLoading(false);
                    setValueEdit(undefined);
                }, 2000)
            }
        } catch (err) {
            console.log('Delete User Exception :', err);
            openNotificationWithIcon('error', 'Data not deleted');
        }
    }

    const resetFields = () => {
        setFirstName();
        setMiddleName();
        setLastName();
        setEmail();
        setUserName();
        setPassword();
        setConfirmPassword();
        setBirthdate();
        setGender();
        setLocation();
        setRole();
    }

    const openNotificationWithIcon = (type, title, description) => {
        api[type]({
            message: title,
            description,
        });
    };

    return (
        <div>
            <div style={{ textAlign: 'end' }}>
                <Button type="primary" onClick={() => onClickHandler('addUser')}>Create New User</Button>
                <br />
                <br />
            </div>
            <TableSort data={allUsers} pagination={pagination} onChange={onChangeHandler} onClick={onClickHandler} columns={columns} loading={tableLoading} />
            {showModal ?
                <ModalComponent
                    open={showModal}
                    title={modalTitle}
                    loading={loading}
                    saveData={saveUser}
                    onCancel={() => { setLoading(false); resetFields(); setShowModal(false) }}>
                    {modalTitle !== 'Are you Sure' ? <AddEditUser
                        onChange={onChangeHandler}
                        first_name={firstName}
                        middle_name={middleName}
                        last_name={lastName}
                        email={email}
                        username={username}
                        password={password}
                        confirm_password={confirmPassword}
                        birthdate={birthdate}
                        gender={gender}
                        location={location}
                        role={role}
                    /> : <p>This will delete the user "{valueEdit.name}" permanently from the system.</p>}
                </ModalComponent>
                : null}
            {contextHolder}
        </div>
    )
}